import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-end flex-sm-row flex-column mx-3"},[_c(VBtn,{on:{"click":function($event){return _vm.downloadExcel(_vm.summaryByProductTime.details)}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Download ")],1)],1),_c('div',{staticClass:"mt-5"},[_c(VTabs,{attrs:{"fixed-tabs":"","show-arrows":_vm.isMobile},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.types),function(type){return _c(VTab,{key:type},[_vm._v(" "+_vm._s(type)+" ")])}),1),_c(VTabsItems,{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.types),function(type){return _c(VTabItem,{key:type,staticClass:"ma-0 ma-md-5"},[(type == 'sold')?_c('sold-events',{attrs:{"eventList":_vm.groups[type].events,"date":_vm.date,"domain":_vm.domain}}):_vm._e(),(type == 'adjustments')?_c('adjustment-events',{attrs:{"eventList":_vm.groups[type].events,"date":_vm.date,"domain":_vm.domain}}):_vm._e(),(type == 'purchases')?_c('purchase-events',{attrs:{"eventList":_vm.groups[type].events,"date":_vm.date,"domain":_vm.domain}}):_vm._e(),(type == 'inputs')?_c('process-input-events',{attrs:{"eventList":_vm.groups[type].events,"date":_vm.date,"domain":_vm.domain}}):_vm._e(),(type == 'outputs')?_c('process-output-events',{attrs:{"eventList":_vm.groups[type].events,"date":_vm.date,"domain":_vm.domain}}):_vm._e(),(type == 'process')?_c('process-events',{attrs:{"eventList":_vm.groups['process'].events,"date":_vm.date,"domain":_vm.domain},model:{value:(_vm.processData),callback:function ($$v) {_vm.processData=$$v},expression:"processData"}}):_vm._e()],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsDivider } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inventoryLabResultCreate"},[_c(VBreadcrumbs,[_c(VBreadcrumbsItem,{attrs:{"exact":true,"to":{ name: 'home', params: {} }}},[_vm._v("Home")]),_c(VBreadcrumbsDivider,[_vm._v("/")]),_c(VBreadcrumbsItem,{attrs:{"exact":true,"to":{ name: 'inventoryLabResult', params: {} }}},[_vm._v(" Inventory Lab Result ")]),_c(VBreadcrumbsDivider,[_vm._v("/")]),_c(VBreadcrumbsItem,{attrs:{"to":{ name: 'inventoryCoaCheck', params: {} }}},[_vm._v(" Coa Check ")])],1),_c(VCard,{staticClass:"py-10"},[_c(VCardTitle,[_vm._v("Choose a product to check inventories without COA")]),_c(VCardText,[_c('product-select',{attrs:{"manufactured-only":"","return-object":""},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1),_c(VCardActions,[_c(VBtn,{on:{"click":_vm.goBack}},[_vm._v(" Back ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":_vm.findWithoutCoa}},[_vm._v("Find Inventory Without CoA")]),_c(VSpacer)],1)],1),_c(VSheet,{staticClass:"my-10"},[_c(VDataTable,{attrs:{"items":_vm.matchingResults,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.id",fn:function({ item }){return [_c('a',{attrs:{"color":"primary"},on:{"click":function($event){_vm.lookupInventory = true;
            _vm.selectedRow = item;
            _vm.selectedInventory = item.id;}}},[_vm._v(_vm._s(item.id))])]}},{key:"item.unitAvailable",fn:function({ item }){return [_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.unitAvailable)+" ")])]}}],null,true)},[_c(VList,{staticClass:"pa-5"},_vm._l((item.unitDetails),function(unit,index){return _c(VListItem,{key:unit.id},[_c(VListItemSubtitle,[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(_vm._f("formatNumber")(unit.amountPerUnit))+" "+_vm._s(unit.unitMeasure)+" ("+_vm._s(unit.code)+") ")])],1)}),1)],1)]}}])})],1),_c('inventory-lookup',{attrs:{"activated":_vm.lookupInventory},on:{"update:activated":function($event){_vm.lookupInventory=$event}},model:{value:(_vm.selectedInventory),callback:function ($$v) {_vm.selectedInventory=$$v},expression:"selectedInventory"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
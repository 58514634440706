import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,[_c('vuedraggable',{attrs:{"list":_vm.label.weightInfoList}},[_c('transition-group',{staticClass:"row"},_vm._l((_vm.label.weightInfoList),function(weight){return _c(VCol,{key:weight.index,class:weight.checked ? '' : 'd-print-none',style:({
          paddingTop: _vm.settings.top + 'px',
          paddingRight: _vm.settings.right + 'px',
          paddingBottom: _vm.settings.bottom + 'px',
          paddingLeft: _vm.settings.left + 'px',
          fontSize: _vm.settings.fontSize + 'pt',
        }),attrs:{"cols":"12","sm":"6","md":12 / _vm.settings.cols}},[_c('div',{staticStyle:{"margin-bottom":"5px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(weight.checked),expression:"weight.checked"}],staticClass:"d-print-none",staticStyle:{"margin-right":"2px"},attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(weight.checked)?_vm._i(weight.checked,null)>-1:(weight.checked)},on:{"change":function($event){var $$a=weight.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(weight, "checked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(weight, "checked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(weight, "checked", $$c)}}}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Bergsoe Metals Co. Ltd.")]),_c(VIcon,{staticClass:"d-print-none",staticStyle:{"margin-left":"5px","color":"blue"},attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteWeight(weight.index)}}},[_vm._v("delete ")])],1),_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"position":"relative","clear":"both"},style:({ width: _vm.settings.qrSize + 'px' })},[_c('img',{staticStyle:{"position":"absolute","clear":"both"},style:({
                width: _vm.settings.qrSize / 5 + 'px',
                marginTop: (_vm.settings.qrSize / 10) * 4 + 'px',
                marginLeft: (_vm.settings.qrSize / 10) * 4 + 'px',
              }),attrs:{"src":require("../../assets/logo.png")}}),_c('qriously',{attrs:{"value":_vm.label.productName +
                ';' +
                _vm.label.stockDate +
                ';' +
                _vm.label.prefix +
                '-' +
                _vm.label.code +
                '-' +
                weight.index +
                ';' +
                weight.weight,"size":_vm.displaySettings.qrSize}})],1),_c('div',{staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.label.productName)),_c('br'),_vm._v(" "+_vm._s(_vm.label.prefix)+"-"+_vm._s(_vm.label.code)+"-"+_vm._s(weight.index)),_c('br'),_vm._v(" "+_vm._s(_vm.label.stockDate)),_c('br'),(weight.editFlag)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(weight.weight),expression:"weight.weight"}],staticStyle:{"border-radius":"10px"},attrs:{"type":"nubmer"},domProps:{"value":(weight.weight)},on:{"blur":function($event){return _vm.weightChange(weight.index)},"input":function($event){if($event.target.composing)return;_vm.$set(weight, "weight", $event.target.value)}}}):_c('span',[_vm._v(_vm._s(weight.weight)+"kg")]),_c(VIcon,{staticClass:"d-print-none",staticStyle:{"margin-left":"5px","color":"blue"},attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.weightEdit(weight.index)}}},[_vm._v("edit ")])],1)])])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsDivider } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"labelGenEdit"},[_c(VBreadcrumbs,[_c(VBreadcrumbsItem,{attrs:{"exact":true,"to":{ name: 'home', params: {} }}},[_vm._v("Home")]),_c(VBreadcrumbsDivider,[_vm._v("/")]),_c(VBreadcrumbsItem,{attrs:{"exact":true,"to":{ name: 'labelGen', params: {} }}},[_vm._v(" Label Gen ")]),_c(VBreadcrumbsDivider,[_vm._v("/")]),_c(VBreadcrumbsItem,{attrs:{"to":{ name: 'labelGenEdit', params: { id: _vm.id } }}},[_vm._v(" Edit Label Gen ")])],1),_c(VCard,[_c(VCardTitle,[_vm._v("Editor")]),_c(VCardText,[_c(VSheet,{staticClass:"d-flex"},[_c('div',[_c('label',[_vm._v("Product")]),_c('a',{on:{"click":function($event){_vm.activateProductLookup = true}}},[_vm._v(_vm._s(_vm.product ? _vm.product.name:'Choose a product'))])])])],1)],1),_c('product-lookup',{attrs:{"activated":_vm.activateProductLookup},on:{"selected":_vm.assignProduct},model:{value:(_vm.productId),callback:function ($$v) {_vm.productId=$$v},expression:"productId"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
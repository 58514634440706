import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c(VCard,{staticClass:"my-2",attrs:{"loading":_vm.loading}},[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm.order.customerName))]),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-0 ml-md-2",attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,[(_vm.order.status == 'Completed')?_c(VListItem,{attrs:{"ripple":""},on:{"click":_vm.markOrderAsIncomplete}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-check-circle-outline")])],1),_c(VListItemTitle,[_vm._v("Mark as Incomplete")])],1):_c(VListItem,{attrs:{"ripple":""},on:{"click":_vm.markOrderAsCompleted}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-check-circle-outline")])],1),_c(VListItemTitle,[_vm._v("Mark as Completed")])],1)],1)],1)],1),_c(VCardSubtitle,{staticClass:"d-flex flex-row justify-space-around"},[_c('span',[_vm._v("ID: "+_vm._s(_vm.order.id))]),_vm._v(" "),_c(VDivider,{staticClass:"mx-2",attrs:{"vertical":""}}),_c('span',[_vm._v("Code: "+_vm._s(_vm.order.salesOrderCode))]),_c(VDivider,{staticClass:"mx-2",attrs:{"vertical":""}}),_c('span',[_vm._v("Status: "+_vm._s(_vm.order.status))]),_c(VDivider,{staticClass:"mx-2",attrs:{"vertical":""}}),_c('span',[_vm._v("Date: "+_vm._s(_vm._f("formatDateYear")(_vm.order.salesDate)))])],1),_c(VCardText,[(_vm.order.summaries)?_c('div',_vm._l((_vm.order.summaries),function(summary){return _c('div',{key:summary.productId,staticClass:"d-flex justify-space-between flex-column flex-md-row my-4",attrs:{"set":(_vm.measureUnit =
              summary.product.measureType == 'unit'
                ? summary.product.measureUnit
                : summary.product.measureWeight)}},[_c('div',{staticClass:"flex-grow-1 text-center mx-0 mx-md-8",attrs:{"set":(_vm.percent = _vm.percentFilled(summary))}},[_c('div',[_vm._v(_vm._s(summary.product.name))]),_c(VProgressLinear,{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"pointer",attrs:{"value":_vm.percent,"color":"primary","height":"25"},on:{"click":_vm.showPackingList},scopedSlots:_vm._u([{key:"default",fn:function({ value }){return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true)}),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(summary.filledWeight))+" of "+_vm._s(_vm._f("formatNumber")(summary.weight))+" "+_vm._s(_vm.measureUnit)+" ")])],1),_c('div',{key:_vm.statusUpdateTs,staticClass:"my-4 my-md-auto",attrs:{"set":(_vm.pendings = _vm.getPendingPackages(
                _vm.order.customerId,
                summary.productId
              ))}},[(_vm.order.status != 'Completed')?_c(VBtn,{attrs:{"disabled":!_vm.pendings || _vm.pendings.length == 0,"outlined":"","small":""},on:{"click":function($event){return _vm.viewWorksheets(_vm.order, summary.productId)}}},[_vm._v(" "+_vm._s(_vm.pendings ? _vm.pendings.length : 0)+" Pending Worksheets ")]):_vm._e()],1)])}),0):_c('div',{staticClass:"text-center text-h6 ma-4"},[_vm._v("No Product")]),_c('div',{staticClass:"text-caption text-right"},[_vm._v(" "+_vm._s(_vm._f("formatDateYear")(_vm.order.salesDate))+" by "+_vm._s(_vm.order.salesPersonName)+" ")])])],1),_c(VDialog,{staticStyle:{"z-index":"1000"},model:{value:(_vm.worksheetDialog),callback:function ($$v) {_vm.worksheetDialog=$$v},expression:"worksheetDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"justify-end"},[_c('div',[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.worksheetDialog = null}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)]),_c(VCardText,_vm._l((_vm.selectedWorksheets),function(worksheet,index){return _c('worksheet-card',{key:worksheet ? worksheet.id : 'null',attrs:{"worksheet":worksheet,"showEditButtons":true,"editButtons":['print', 'save-packing-list'],"index":index + 1,"salesOrderId":_vm.order.id},on:{"print":_vm.print,"used":_vm.usedWorksheet}})}),1)],1)],1),_c(VDialog,{staticStyle:{"z-index":"1000"},model:{value:(_vm.packingListDialog),callback:function ($$v) {_vm.packingListDialog=$$v},expression:"packingListDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"justify-end"},[_c('div',[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.packingListDialog = null}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)]),_c(VCardSubtitle,{staticClass:"text-center font-weight-light"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.label.foundX", _vm.packingLists ? _vm.packingLists.length : 0 ))+" ")]),_c(VCardText,[_c('packing-list',{staticClass:"ma-4",attrs:{"items":_vm.packingLists},on:{"updated":_vm.packingListChanged}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-sheet class="mx-md-5 mx-2 mt-4">
    <v-card :loading="isAppLoading">
      <!-- Date Range Inputs -->
      <v-card-text class="d-flex flex-wrap py-6">
        <v-text-field
          class="mr-md-2 mb-6 mb-md-0"
          v-model="parameters.startDate"
          :label="$vuetify.lang.t('$vuetify.base.startDate')"
          type="date"
          clearable
          dense
          hide-details
          :rules="[rules.required]"
        />

        <v-text-field
          class="ml-0 ml-lg-4 mb-6 mb-md-0"
          v-model="parameters.endDate"
          :label="$vuetify.lang.t('$vuetify.base.endDate')"
          type="date"
          clearable
          dense
          hide-details
          :rules="[rules.dateRange(parameters.startDate, parameters.endDate)]"
        />
      </v-card-text>

      <!-- Submit Button -->
      <v-card-actions class="justify-center my-4">
        <v-btn @click="search" :disabled="isAppLoading">
          {{ $vuetify.lang.t("$vuetify.base.submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-divider class="my-4" />

    <!-- Filter Row (if data loaded) -->
    <v-container>
      <v-row v-if="hasSearched && filteredSummaryData.length > 0">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn class="mr-auto" color="green" @click="downloadExcel">
            Download Excel
          </v-btn>
          <v-btn
            v-if="anyFilterApplied"
            small
            text
            color="red"
            class="mr-2"
            @click="clearAllFilters"
          >
            Clear All Filters
          </v-btn>
          <v-btn icon @click="toggleFilters">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </v-col>

        <!-- Filter Panel -->
        <v-col cols="12">
          <v-expand-transition>
            <div v-if="showFilterOptions" class="pb-4">
              <v-card outlined>
                <v-card-title>Filters</v-card-title>
                <v-card-text>
                  <v-row>
                    <!-- Supplier Multi-Select -->
                    <v-col cols="12">
                      <v-select
                        v-model="selectedSuppliers"
                        :items="supplierOptions"
                        item-text="supplierName"
                        item-value="supplierName"
                        multiple
                        clearable
                        :label="$vuetify.lang.t('$vuetify.base.supplier')"
                        dense
                        @change="onFilterChange('supplier')"
                      >
                        <template v-slot:append>
                          <v-btn small text @click="selectAll('supplier')">
                            Select All
                          </v-btn>
                          <v-btn small text @click="clearAll('supplier')">
                            Clear
                          </v-btn>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!-- Product Multi-Select -->
                    <v-col cols="12" md="6">
                      <v-select
                        v-model="selectedProducts"
                        :items="filteredProductOptions"
                        item-text="productName"
                        item-value="productName"
                        multiple
                        clearable
                        :label="$vuetify.lang.t('$vuetify.base.product')"
                        dense
                        @change="onFilterChange('product')"
                      >
                        <template v-slot:append>
                          <v-btn small text @click="selectAll('product')">
                            Select All
                          </v-btn>
                          <v-btn small text @click="clearAll('product')">
                            Clear
                          </v-btn>
                        </template>
                      </v-select>
                    </v-col>

                    <!-- Vehicle Plate Number Multi-Select -->
                    <v-col cols="12" md="6">
                      <v-select
                        v-model="selectedVehiclePlates"
                        :items="filteredVehiclePlateOptions"
                        item-text="vehiclePlateNumber"
                        item-value="vehiclePlateNumber"
                        multiple
                        clearable
                        :label="$vuetify.lang.t('$vuetify.worksheet.plateNumber')"
                        dense
                        @change="onFilterChange('vehiclePlate')"
                      >
                        <template v-slot:append>
                          <v-btn small text @click="selectAll('vehiclePlate')">
                            Select All
                          </v-btn>
                          <v-btn small text @click="clearAll('vehiclePlate')">
                            Clear
                          </v-btn>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <!-- Min/Max Percentage -->
                  <v-row class="mt-3">
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model.number="adjMin"
                        type="number"
                        :label="$vuetify.lang.t('$vuetify.base.minAdjustment')"
                        dense
                        clearable
                        :rules="[rules.minMax]"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model.number="adjMax"
                        type="number"
                        :label="$vuetify.lang.t('$vuetify.base.maxAdjustment')"
                        dense
                        clearable
                        :rules="[rules.minMax]"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-expand-transition>
        </v-col>
      </v-row>
    </v-container>

    <!-- Summary Section -->
    <v-container>
      <v-row>
        <v-col cols="12">
          <h4>{{ $vuetify.lang.t("$vuetify.summary.summary") }}</h4>
          <v-simple-table>
            <thead>
              <tr>
                <th>{{ $vuetify.lang.t("$vuetify.base.supplier") }}</th>
                <th>{{ $vuetify.lang.t("$vuetify.base.product") }}</th>
                <th>Product Weight</th>
                <th>Net Adjustment</th>
                <th>Sample Adjustment</th>
                <th>Sample Adjustment (%)</th>
                <th>Deliveries</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(summary, idx) in filteredSummaryData" :key="idx">
                <td>{{ summary.supplierName }}</td>
                <td>{{ summary.productName }}</td>
                <td>{{ formatNumber(summary.productWeight) }}</td>
                <td>{{ formatNumber(summary.existingNetAdjustmentWeight) }}</td>
                <td>{{ formatNumber(summary.sampleAdjustmentWeight) }}</td>
                <td>{{ formatNumber(summary.sampleAdjustmentPercent) }}%</td>
                <td>
                  <v-btn
                    text
                    small
                    @click="
                      showDetails(summary.supplierName, summary.productName)
                    "
                  >
                    {{ summary.deliveriesCount }}
                  </v-btn>
                </td>
              </tr>
              <!-- Total Row for Summary -->
              <tr>
                <td><strong>Total</strong></td>
                <td></td>
                <td><strong>{{ formatNumber(summaryTotals.productWeight) }}</strong></td>
                <td><strong>{{ formatNumber(summaryTotals.existingNetAdjustmentWeight) }}</strong></td>
                <td><strong>{{ formatNumber(summaryTotals.sampleAdjustmentWeight) }}</strong></td>
                <td><strong>{{ formatNumber(summaryTotals.sampleAdjustmentPercent) }}%</strong></td>
                <td><strong>{{ summaryTotals.deliveriesCount }}</strong></td>
              </tr>
              <tr v-if="filteredSummaryData.length === 0">
                <td colspan="7" class="text-center">
                  {{ $vuetify.lang.t("$vuetify.dataIterator.noResultsText") }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-divider class="my-4" />

      <!-- Detail Section -->
      <v-row
        v-if="filteredDetailedData && filteredDetailedData.length > 0"
        ref="detailsSection"
      >
        <v-col cols="12">
          <h4>{{ $vuetify.lang.t("$vuetify.base.detail") }}</h4>

          <!-- Client-side search text -->
          <v-text-field
            v-model="searchText"
            :label="$vuetify.lang.t('$vuetify.base.search')"
            dense
            clearable
            class="mb-4"
          />
          
          <!-- Data Table (Detail) -->
          <v-data-table
            :headers="detailHeaders"
            :items="filteredDetailedData"
            :items-per-page="10"
            class="elevation-1"
            :sort-by.sync="tableOptions.sortBy"
            :sort-desc.sync="tableOptions.sortDesc"
            multi-sort
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $vuetify.lang.t("$vuetify.sideBar.purchasedBattery") }}
                </v-toolbar-title>
              </v-toolbar>
            </template>

            <!-- "Product Weight" = receivingGrossWeight - receivingDeductionWeight -->
            <template v-slot:item.productWeight="{ item }">
              <span>{{ formatNumber(item.productWeight) }}</span>
            </template>

            <!-- Net Adjustment = existingNetAdjustmentWeight -->
            <template v-slot:item.netAdjustment="{ item }">
              <span>{{ formatNumber(item.netAdjustment) }}</span>
            </template>

            <!-- Sample Adjustment = calculatedNetAdjustmentWeight -->
            <template v-slot:item.sampleAdjustment="{ item }">
              <span>{{ formatNumber(item.sampleAdjustment) }}</span>
            </template>

            <!-- Sample Adjustment (%) = batterySamplingPercentage -->
            <template v-slot:item.sampleAdjustmentPct="{ item }">
              <span>{{ formatNumber(item.sampleAdjustmentPct) }}%</span>
            </template>

            <!-- Worksheet IDs clickable -->
            <template v-slot:item.receivingWorksheetId="{ item }">
              <v-btn
                text
                small
                color="primary"
                @click="
                  openWorksheetDialog(
                    item.receivingWorksheetId,
                    item.supplierName,
                    item.productName
                  )
                "
              >
                {{ item.receivingWorksheetId }}
              </v-btn>
            </template>
            <template v-slot:item.batterySamplingWorksheetId="{ item }">
              <v-btn
                text
                small
                color="primary"
                @click="
                  openWorksheetDialog(
                    item.batterySamplingWorksheetId,
                    item.supplierName,
                    item.productName
                  )
                "
              >
                {{ item.batterySamplingWorksheetId }}
              </v-btn>
            </template>

            <!-- Footer Slot for Totals -->
            <template v-slot:body.append="{}">
              <tr>
                <td><strong>Total</strong></td>
                <td></td>
                <td></td>
                <td><strong>{{ formatNumber(detailTotals.productWeight) }}</strong></td>
                <td><strong>{{ formatNumber(detailTotals.netAdjustment) }}</strong></td>
                <td><strong>{{ formatNumber(detailTotals.sampleAdjustment) }}</strong></td>
                <td><strong>{{ formatNumber(detailTotals.sampleAdjustmentPct) }}%</strong></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- If no detail data after filters -->
      <v-row v-else-if="hasSearched">
        <v-col cols="12" class="text-center">
          <p>{{ $vuetify.lang.t("$vuetify.dataIterator.noResultsText") }}</p>
        </v-col>
      </v-row>
    </v-container>

    <!-- Worksheet Dialog: Fullscreen with WorksheetCard inside -->
    <custom-dialog
      :fullscreen="true"
      v-model="showWorksheetDialog"
      :closeable="true"
    >
      <template #title>
        {{ selectedSlot.supplierName }} |
        {{ selectedSlot.productName || "Worksheet" }}
      </template>
      <template #default>
        <!-- We only show <worksheet-card> if we have a selectedWorksheetId -->
        <worksheet-card
          v-if="selectedWorksheetId"
          class="mb-4"
          :worksheet="selectedWorksheetId"
          :key="selectedWorksheetId"
        />
      </template>
    </custom-dialog>
  </v-sheet>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import js2excel from "js2excel";

const WorksheetCard = () =>
  import("@/components/processWorksheet/WorksheetCard.vue");
const CustomDialog = () => import("@/components/common/CustomDialog.vue");

export default {
  components: {
    WorksheetCard,
    CustomDialog,
  },

  data() {
    return {
      isAppLoading: false,
      hasSearched: false,
      showFilterOptions: false,

      // Worksheet dialog states
      showWorksheetDialog: false,
      selectedWorksheetId: null,
      selectedSlot: {
        supplierName: "",
        productName: "",
      },

      parameters: {
        startDate: "",
        endDate: "",
      },
      detailedData: [],
      // Removed summaryData from data()

      // User input
      searchText: "",
      supplierOptions: [],
      selectedSuppliers: [],
      productOptions: [],
      selectedProducts: [],
      vehiclePlateOptions: [], // Vehicle Plate Options
      selectedVehiclePlates: [], // Selected Vehicle Plates
      adjMin: 0,
      adjMax: 99,

      tableOptions: {
        sortBy: ["processDate", "supplierName", "vehiclePlateNumber"],
        sortDesc: [false, false, false],
      },

      // Updated detail headers (added sampleAdjustmentPct)
      detailHeaders: [
        { text: "Date", value: "processDate" },
        { text: "Supplier", value: "supplierName" },
        { text: "Plate", value: "vehiclePlateNumber" },
        // "Product Weight" => productWeight
        { text: "Product Weight", value: "productWeight" },
        // "Net Adjustment" => netAdjustment
        { text: "Net Adjustment", value: "netAdjustment" },
        // "Sample Adjustment" => sampleAdjustment
        { text: "Sample Adjustment", value: "sampleAdjustment" },
        // New column => sampleAdjustmentPct
        { text: "Sample Adjustment (%)", value: "sampleAdjustmentPct" },
        {
          text: "Receiving WS ID",
          value: "receivingWorksheetId",
        },
        {
          text: "Battery WS ID",
          value: "batterySamplingWorksheetId",
        },
        { text: "Product", value: "productName" },
      ],

      rules: {
        required: (value) => !!value || "This field is required",
        dateRange: (start, end) =>
          (start && end && new Date(start) <= new Date(end)) ||
          "Invalid date range",
        minMax: () => {
          if (this.adjMin > this.adjMax) {
            return "Min Adjustment cannot be greater than Max Adjustment";
          }
          return true;
        },
      },
    };
  },

  computed: {
    anyFilterApplied() {
      return (
        this.selectedSuppliers.length > 0 ||
        this.selectedProducts.length > 0 ||
        this.selectedVehiclePlates.length > 0 || // Vehicle Plate Filter
        this.adjMin !== 0 ||
        this.adjMax !== 99 ||
        this.searchText.trim() !== ""
      );
    },

    // If no supplier chosen, show all products; else filter
    filteredProductOptions() {
      if (this.selectedSuppliers.length === 0) {
        return this.productOptions;
      }
      const availableProducts = new Set();
      this.detailedData.forEach((item) => {
        if (this.selectedSuppliers.includes(item.supplierName)) {
          availableProducts.add(item.productName);
        }
      });
      return this.productOptions.filter((p) =>
        availableProducts.has(p.productName)
      );
    },

    /**
     * Returns vehicle plate options filtered by selected suppliers.
     * If no suppliers are selected, returns all vehicle plates.
     */
    filteredVehiclePlateOptions() {
      if (this.selectedSuppliers.length === 0) {
        return this.vehiclePlateOptions;
      }
      const plates = new Set();
      this.detailedData.forEach(item => {
        if (this.selectedSuppliers.includes(item.supplierName)) {
          plates.add(item.vehiclePlateNumber);
        }
      });
      return this.vehiclePlateOptions.filter(o => plates.has(o.vehiclePlateNumber));
    },

    /**
     * Adjusts any selected vehicle plates that are no longer available after supplier selection.
     */
    adjustedSelectedVehiclePlates() {
      const availablePlates = this.filteredVehiclePlateOptions.map(o => o.vehiclePlateNumber);
      return this.selectedVehiclePlates.filter(plate => availablePlates.includes(plate));
    },

    /**
     * Filters the detailed data based on user inputs.
     */
    filteredDetailedData() {
      if (!this.hasSearched) return [];

      // Create a copy so we can mutate
      const result = this.detailedData.map((item) => {
        return {
          ...item,
          productWeight:
            (parseFloat(item.receivingGrossWeight) || 0) -
            (parseFloat(item.receivingDeductionWeight) || 0),
          netAdjustment: parseFloat(item.existingNetAdjustmentWeight) || 0,
          sampleAdjustment:
            parseFloat(item.calculatedNetAdjustmentWeight) || 0,
          sampleAdjustmentPct:
            parseFloat(item.batterySamplingPercentage) || 0,
        };
      });

      // Now apply the user’s filters
      let filtered = result;

      if (this.selectedSuppliers.length > 0) {
        filtered = filtered.filter((row) =>
          this.selectedSuppliers.includes(row.supplierName)
        );
      }
      if (this.selectedProducts.length > 0) {
        filtered = filtered.filter((row) =>
          this.selectedProducts.includes(row.productName)
        );
      }
      if (this.selectedVehiclePlates.length > 0) { // Vehicle Plate Filter
        filtered = filtered.filter((row) =>
          this.selectedVehiclePlates.includes(row.vehiclePlateNumber)
        );
      }
      filtered = filtered.filter((row) => {
        const pct = Math.abs(row.sampleAdjustmentPct || 0);
        return pct >= this.adjMin && pct <= this.adjMax;
      });
      if (this.searchText.trim() !== "") {
        const s = this.searchText.toLowerCase();
        filtered = filtered.filter((row) =>
          JSON.stringify(row).toLowerCase().includes(s)
        );
      }
      return filtered;
    },

    /**
     * Generates summary data based on the currently filtered detailed data.
     * This ensures the summary reflects all active filters, including vehicle plates.
     */
     filteredSummaryData() {
      if (!this.hasSearched) return [];

      // Aggregate data by supplier and product
      const summaryMap = {};
      this.filteredDetailedData.forEach((item) => {
        const key = `${item.supplierName}||${item.productName}`;
        if (!summaryMap[key]) {
          summaryMap[key] = {
            supplierName: item.supplierName,
            productName: item.productName,
            totalGrossWeight: 0,
            totalDeductionWeight: 0,
            deliveriesCount: 0,
            existingNetAdjustmentWeight: 0, // Initialize as 0 for summation
            sampleAdjustmentWeight: 0,      // Initialize as 0 for summation
            batterySamplingSum: 0,
          };
        }
        summaryMap[key].totalGrossWeight += parseFloat(item.receivingGrossWeight) || 0;
        summaryMap[key].totalDeductionWeight += parseFloat(item.receivingDeductionWeight) || 0;
        
        // **Corrected Aggregation: Sum the fields instead of overwriting**
        summaryMap[key].existingNetAdjustmentWeight += parseFloat(item.existingNetAdjustmentWeight) || 0;
        summaryMap[key].sampleAdjustmentWeight += parseFloat(item.sampleAdjustment) || 0;
        
        summaryMap[key].batterySamplingSum += parseFloat(item.sampleAdjustmentPct) || 0;
        summaryMap[key].deliveriesCount += 1;
      });

      // Convert summaryMap to an array and compute derived fields
      let summaries = Object.values(summaryMap).map((s) => {
        const productWeight = (s.totalGrossWeight || 0) - (s.totalDeductionWeight || 0);
        let sampleAdjustmentPercent = 0;
        if (s.deliveriesCount > 0) {
          sampleAdjustmentPercent = (s.batterySamplingSum / s.deliveriesCount).toFixed(2);
        }
        return {
          ...s,
          productWeight,
          sampleAdjustmentPercent,
        };
      });

      // Apply additional filters: adjMin and adjMax
      summaries = summaries.filter((item) => {
        const absPct = Math.abs(item.sampleAdjustmentPercent || 0);
        return absPct >= this.adjMin && absPct <= this.adjMax;
      });

      return summaries;
    },


    /**
     * Computes totals for the summary table based on the filtered summaries.
     */
    summaryTotals() {
      const total = {
        supplierName: "Total",
        productName: "",
        productWeight: 0,
        existingNetAdjustmentWeight: 0,
        sampleAdjustmentWeight: 0,
        sampleAdjustmentPercent: 0,
        deliveriesCount: 0,
      };

      this.filteredSummaryData.forEach((row) => {
        total.productWeight += parseFloat(row.productWeight) || 0;
        total.existingNetAdjustmentWeight += parseFloat(row.existingNetAdjustmentWeight) || 0;
        total.sampleAdjustmentWeight += parseFloat(row.sampleAdjustmentWeight) || 0;
        total.sampleAdjustmentPercent += parseFloat(row.sampleAdjustmentPercent) || 0;
        total.deliveriesCount += parseInt(row.deliveriesCount) || 0;
      });

      if (total.deliveriesCount > 0) {
        total.sampleAdjustmentPercent = (
          total.sampleAdjustmentPercent / total.deliveriesCount
        ).toFixed(2);
      } else {
        total.sampleAdjustmentPercent = "0.00";
      }

      return total;
    },

    /**
     * Computes totals for the detail table based on the filtered detailed data.
     */
    detailTotals() {
      const total = {
        processDate: "",
        supplierName: "Total",
        vehiclePlateNumber: "",
        productWeight: 0,
        netAdjustment: 0,
        sampleAdjustment: 0,
        sampleAdjustmentPct: 0,
        receivingWorksheetId: "",
        batterySamplingWorksheetId: "",
        productName: "",
      };

      this.filteredDetailedData.forEach((row) => {
        total.productWeight += parseFloat(row.productWeight) || 0;
        total.netAdjustment += parseFloat(row.netAdjustment) || 0;
        total.sampleAdjustment += parseFloat(row.sampleAdjustment) || 0;
        total.sampleAdjustmentPct += parseFloat(row.sampleAdjustmentPct) || 0;
      });

      const count = this.filteredDetailedData.length;
      if (count > 0) {
        total.sampleAdjustmentPct = (
          total.sampleAdjustmentPct / count
        ).toFixed(2);
      } else {
        total.sampleAdjustmentPct = "0.00";
      }

      return total;
    },
  },

  watch: {
    selectedSuppliers(newSuppliers) {
      // Adjust selectedVehiclePlates based on the new supplier selection
      this.selectedVehiclePlates = this.adjustedSelectedVehiclePlates;
    }
  },

  mounted() {
    const { start, end } = this.$route.query;
    if (start && end) {
      this.parameters.startDate = start;
      this.parameters.endDate = end;
    } else {
      this.parameters.startDate = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      this.parameters.endDate = moment().endOf("month").format("YYYY-MM-DD");
      this.updateURLQuery();
    }
    this.search();
  },

  methods: {
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),

    updateURLQuery() {
      const newQuery = {
        ...this.$route.query,
        start: this.parameters.startDate,
        end: this.parameters.endDate,
      };
      this.$router.replace({ query: newQuery }).catch(() => {});
    },

    async search() {
      if (!this.parameters.startDate || !this.parameters.endDate) {
        this.addErrorMessage("Invalid date range");
        return;
      }
      this.isAppLoading = true;
      try {
        // 1) Fetch data
        const resp = await this.$axios.get(
          "/reports/receiving/purchased-battery-report",
          {
            params: {
              start: this.parameters.startDate,
              end: this.parameters.endDate,
            },
          }
        );
        const data = resp.data || [];

        // Debug: Log fetched data
        console.log("Fetched Data:", data);

        // 2) Build detail data
        this.detailedData = data;

        // 4) Build supplier, product & vehicle plate arrays
        const uniqueSuppliers = new Set();
        const uniqueProducts = new Set();
        const uniqueVehiclePlates = new Set();
        data.forEach((item) => {
          uniqueSuppliers.add(item.supplierName);
          uniqueProducts.add(item.productName);
          uniqueVehiclePlates.add(item.vehiclePlateNumber);
        });
        this.supplierOptions = [...uniqueSuppliers]
          .sort((a, b) => a.localeCompare(b))
          .map((supplierName) => ({
            supplierName,
          }));
        this.productOptions = [...uniqueProducts].map((productName) => ({
          productName,
        }));
        this.vehiclePlateOptions = [...uniqueVehiclePlates]
          .sort((a, b) => a.localeCompare(b))
          .map((vehiclePlateNumber) => ({
            vehiclePlateNumber,
          }));

        this.updateURLQuery();
      } catch (err) {
        console.error(err);
        this.addErrorMessage("Failed to fetch data");
      } finally {
        this.isAppLoading = false;
        this.hasSearched = true;
      }
    },

    formatNumber(value) {
      if (value === null || value === undefined) return "";
      return Number(value).toFixed(2);
    },

    // Show detail rows for single supplier+product
    showDetails(supplierName, productName) {
      this.selectedSuppliers = supplierName ? [supplierName] : [];
      this.selectedProducts = productName ? [productName] : [];
      this.$nextTick(() => {
        const el = this.$refs.detailsSection;
        if (el && el.scrollIntoView) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      });
    },

    // Opens the Worksheet dialog
    openWorksheetDialog(worksheetId, supplierName, productName) {
      this.selectedWorksheetId = worksheetId;
      this.selectedSlot.productName = productName;
      this.selectedSlot.supplierName = supplierName;
      this.showWorksheetDialog = true;
    },

    // Toggles the filter panel
    toggleFilters() {
      this.showFilterOptions = !this.showFilterOptions;
    },

    /**
     * Generic method to handle filter changes
     * @param {string} filterType - Type of filter ('supplier', 'product', 'vehiclePlate')
     */
    onFilterChange(filterType) {
      // Additional logic can be added here if needed when filters change
    },

    /**
     * Generic method to select all options for a given filter
     * @param {string} filterType - Type of filter ('supplier', 'product', 'vehiclePlate')
     */
    selectAll(filterType) {
      switch (filterType) {
        case "supplier":
          this.selectedSuppliers = this.supplierOptions.map(
            (o) => o.supplierName
          );
          break;
        case "product":
          this.selectedProducts = this.filteredProductOptions.map(
            (o) => o.productName
          );
          break;
        case "vehiclePlate":
          this.selectedVehiclePlates = this.filteredVehiclePlateOptions.map(
            (o) => o.vehiclePlateNumber
          );
          break;
        default:
          break;
      }
    },

    /**
     * Generic method to clear all selections for a given filter
     * @param {string} filterType - Type of filter ('supplier', 'product', 'vehiclePlate')
     */
    clearAll(filterType) {
      switch (filterType) {
        case "supplier":
          this.selectedSuppliers = [];
          break;
        case "product":
          this.selectedProducts = [];
          break;
        case "vehiclePlate":
          this.selectedVehiclePlates = [];
          break;
        default:
          break;
      }
    },

    // Clear all filters
    clearAllFilters() {
      this.selectedSuppliers = [];
      this.selectedProducts = [];
      this.selectedVehiclePlates = []; // Clear vehicle plates
      this.adjMin = 0;
      this.adjMax = 99;
      this.searchText = "";
    },

      /**
     * Download the detail data as an Excel file using js2excel
     */
    downloadExcel() {
      // Build an array of objects from filteredDetailedData
      const dataForExcel = this.filteredDetailedData.map((row) => ({
        Date: row.processDate,
        Supplier: row.supplierName,
        Plate: row.vehiclePlateNumber,
        "Product Weight": this.formatNumberForExcel(row.productWeight),
        "Net Adjustment": this.formatNumberForExcel(row.netAdjustment),
        "Sample Adjustment": this.formatNumberForExcel(row.sampleAdjustment),
        "Sample Adjustment (%)": this.formatNumberForExcel(row.sampleAdjustmentPct),
        "Receiving WS ID": row.receivingWorksheetId,
        "Battery WS ID": row.batterySamplingWorksheetId,
        Product: row.productName,
      }));

      // Append Total Row for Detail
      dataForExcel.push({
        Date: "",
        Supplier: "Total",
        Plate: "",
        "Product Weight": this.formatNumberForExcel(this.detailTotals.productWeight),
        "Net Adjustment": this.formatNumberForExcel(this.detailTotals.netAdjustment),
        "Sample Adjustment": this.formatNumberForExcel(this.detailTotals.sampleAdjustment),
        "Sample Adjustment (%)": this.formatNumberForExcel(this.detailTotals.sampleAdjustmentPct),
        "Receiving WS ID": "",
        "Battery WS ID": "",
        Product: "",
      });

      // The name for the Excel file
      const filename = `purchased-battery`;

      try {
        // Use js2excel to export
        js2excel.json2excel({
          data: dataForExcel,
          name: filename,
        });
      } catch (error) {
        console.error("Export to Excel failed: ", error);
        this.addErrorMessage("Export to Excel failed");
      }
    },
    /**
     * Formats a number to two decimal places as a numeric value.
     * @param {number} value - The number to format.
     * @returns {number} - The formatted number.
     */
    formatNumberForExcel(value) {
      if (value === null || value === undefined) return 0;
      return Number(parseFloat(value).toFixed(2));
    },
  },
};
</script>

<style scoped></style>

import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VBreadcrumbs,{staticClass:"d-print-none",attrs:{"items":_vm.breadCombs}}),_c(VRow,[_c(VCol,{staticClass:"blue-grey lighten-5",attrs:{"cols":"12","sm":"12","md":"6"}},[_c(VTreeview,{attrs:{"rounded":"","hoverable":"","items":_vm.categories,"activatable":_vm.activatable,"color":_vm.color},on:{"update:active":_vm.updateActive}})],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.qrBind.idText'))+" "+_vm._s(_vm.categoryId))]),_c('div',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.qrBind.nameText'))+" "+_vm._s(_vm.categoryName))]),_c('div',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.qrBind.bindTypeText'))+" "+_vm._s(_vm.bindType))]),_c(VTextField,{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.qrBind.resourceText')},model:{value:(_vm.resource),callback:function ($$v) {_vm.resource=$$v},expression:"resource"}}),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.save.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.base.save')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
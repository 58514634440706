import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"title"},[_vm._v("Product Information")])]),_c(VCardText,[_c('div',[_c('strong',[_vm._v("ID:")]),_vm._v(" "+_vm._s(_vm.product.id))]),_c('div',[_c('strong',[_vm._v("Name:")]),_vm._v(" "+_vm._s(_vm.product.productName))]),_c('div',[_c('strong',[_vm._v("Balance at Date:")]),_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.product.balance))+" ("+_vm._s(_vm.product.balanceDate)+")")])]),_c(VCardTitle,[_c('span',{staticClass:"title"},[_vm._v("Product Rules")])]),_c(VCardText,[_c(VTextField,{attrs:{"label":"Max Production Overlap","type":"number","min":0},model:{value:(_vm.product.maxProductionOverlap),callback:function ($$v) {_vm.$set(_vm.product, "maxProductionOverlap", _vm._n($$v))},expression:"product.maxProductionOverlap"}}),_c(VTextField,{attrs:{"label":"Max Delivery Overlap","type":"number","min":0},model:{value:(_vm.product.maxDeliveryOverlap),callback:function ($$v) {_vm.$set(_vm.product, "maxDeliveryOverlap", _vm._n($$v))},expression:"product.maxDeliveryOverlap"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.updateRules}},[_vm._v("Update Rules")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
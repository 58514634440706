import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-4"},[_c('div',{staticClass:"d-flex flex-row"},[_c(VSelect,{staticClass:"mr-4",attrs:{"items":_vm.myJobs,"item-text":"name","item-value":"id","label":"Choose an active Job","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.selectedJobId),callback:function ($$v) {_vm.selectedJobId=$$v},expression:"selectedJobId"}}),(!_vm.newJob)?_c(VBtn,{staticClass:"my-auto",on:{"click":_vm.showNewJobForm}},[_vm._v(" Create a new Job ")]):_vm._e()],1),(_vm.newJob)?_c('div',{staticClass:"pa-4 my-4"},[_c('div',[_vm._v("New Job Form")]),_c(VForm,{ref:"form"},[_c(VTextField,{attrs:{"rules":[_vm.rules.required],"label":"Job Name"},model:{value:(_vm.newJobName),callback:function ($$v) {_vm.newJobName=$$v},expression:"newJobName"}}),_c(VTextarea,{attrs:{"label":"Job Description"},model:{value:(_vm.newJobDescription),callback:function ($$v) {_vm.newJobDescription=$$v},expression:"newJobDescription"}})],1),_c('div',{staticClass:"d-flex justify-space-around"},[_c(VBtn,{on:{"click":_vm.saveJob}},[_vm._v(" Save Job ")]),_c(VBtn,{on:{"click":function($event){_vm.newJob = false}}},[_vm._v(" Cancel ")])],1)],1):_vm._e(),_c('div',{staticClass:"my-6"},[_c(VDivider),_c('div',{staticClass:"d-flex justify-space-around"},[(!_vm.newJob)?_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.selectJob}},[_vm._v(" Select ")]):_vm._e(),(!_vm.newJob && _vm.selectedJobId)?_c(VBtn,{on:{"click":_vm.deleteJob}},[_vm._v(" Delete Selected Job ")]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
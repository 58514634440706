import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsDivider } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view"},[_c(VBreadcrumbs,[_c(VBreadcrumbsItem,{attrs:{"exact":true,"to":{ name: 'home', params: {} }}},[_vm._v("Home")]),_c(VBreadcrumbsDivider,[_vm._v("/")]),_c(VBreadcrumbsItem,{attrs:{"exact":true,"to":{ name: 'inventoryReservation', params: {} }}},[_vm._v(" Inventory Reservation ")]),_c(VBreadcrumbsDivider,[_vm._v("/")]),_c(VBreadcrumbsItem,{attrs:{"to":{ name: 'inventoryReservationView', params: {} }}},[_vm._v(" View Inventory Reservation ")])],1),(_vm.loaded)?_c(VCard,[_c(VCardTitle,[_vm._v(" View Inventory Reservation ")]),_c(VCardText,[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[(_vm.inventoryReservation.processWorksheetId)?_c('tr',[_c('td',{staticClass:"text-subtitle-1",attrs:{"max-width":"35%"}},[_vm._v("Worksheet ID:")]),_c('td',[_vm._v(_vm._s(_vm.inventoryReservation.processWorksheetId))])]):_vm._e(),(_vm.inventoryReservation.inventoryId)?_c('tr',[_c('td',{staticClass:"text-subtitle-1",attrs:{"max-width":"35%"}},[_vm._v("Inventory ID:")]),_c('td',[_vm._v(_vm._s(_vm.inventoryReservation.inventoryId))])]):_vm._e(),(_vm.inventoryReservation.inventoryUnitId)?_c('tr',[_c('td',{staticClass:"text-subtitle-1",attrs:{"max-width":"35%"}},[_vm._v("Inventory Unit ID:")]),_c('td',[_vm._v(_vm._s(_vm.inventoryReservation.inventoryUnitId))])]):_vm._e(),(_vm.inventoryReservation.quantity)?_c('tr',[_c('td',{staticClass:"text-subtitle-1",attrs:{"max-width":"35%"}},[_vm._v("Quantity:")]),_c('td',[_vm._v(_vm._s(_vm.inventoryReservation.quantity))])]):_vm._e()])]},proxy:true}],null,false,3229532895)})],1)],1):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between ma-5"},[_c(VBtn,{on:{"click":_vm.goBack}},[_vm._v("Back")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.edit(_vm.id)}}},[_vm._v("Edit")]),_c('span')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
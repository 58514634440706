import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c(VDialog,{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v(_vm._s(_vm.activatorIcon ? _vm.activatorIcon : "mdi-cloud-upload-outline"))])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto pa-6",attrs:{"width":"100%"}},[_c(VCardTitle,[_c('span',[_vm._v("Upload New Map")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"label":"Map Name","rules":[_vm.rules.required]},model:{value:(_vm.mapName),callback:function ($$v) {_vm.mapName=$$v},expression:"mapName"}}),_c(VFileInput,{attrs:{"placeholder":"Upload your map","label":"Choose an image file to upload","accept":"image/*","rules":[_vm.rules.required]},on:{"change":_vm.getImageDimensions},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),(_vm.file && _vm.mapDimensions && _vm.mapDimensions.length == 2)?_c('div',{staticClass:"d-flex justify-space-around"},[_c('span',[_vm._v("Width: "+_vm._s(_vm.mapDimensions[0])+"px ")]),_c('span',[_vm._v(" Height: "+_vm._s(_vm.mapDimensions[1])+"px ")]),_c('span',[_vm._v(" Size: "+_vm._s(_vm.fileSize(this.file.size)))])]):_vm._e()],1)],1),_c(VCardActions,{staticClass:"justify-space-around"},[_c(VBtn,{attrs:{"loading":_vm.isAppLoading},on:{"click":_vm.createMap}},[_vm._v(" Create New Map ")]),_c(VBtn,{on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
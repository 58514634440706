import { VBanner } from 'vuetify/lib/components/VBanner';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCol,{attrs:{"cols":"12","sm":"12","md":"6"}},[_c(VSelect,{attrs:{"selectId":_vm.selectId,"items":_vm.items,"label":"printSetting"},on:{"change":_vm.change},model:{value:(_vm.selectId),callback:function ($$v) {_vm.selectId=$$v},expression:"selectId"}}),_c(VBanner,{attrs:{"single-line":"","transition":"slide-y-transition"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"2","md":"2"}},[_c(VTextField,{attrs:{"label":"left"},model:{value:(_vm.item.left),callback:function ($$v) {_vm.$set(_vm.item, "left", $$v)},expression:"item.left"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"2","md":"2"}},[_c(VTextField,{attrs:{"label":"right"},model:{value:(_vm.item.right),callback:function ($$v) {_vm.$set(_vm.item, "right", $$v)},expression:"item.right"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"2","md":"2"}},[_c(VTextField,{attrs:{"label":"top"},model:{value:(_vm.item.top),callback:function ($$v) {_vm.$set(_vm.item, "top", $$v)},expression:"item.top"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"2","md":"2"}},[_c(VTextField,{attrs:{"label":"bottom"},model:{value:(_vm.item.bottom),callback:function ($$v) {_vm.$set(_vm.item, "bottom", $$v)},expression:"item.bottom"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"2","md":"2"}},[_c(VTextField,{attrs:{"label":"QR SIZE"},model:{value:(_vm.item.qrSize),callback:function ($$v) {_vm.$set(_vm.item, "qrSize", $$v)},expression:"item.qrSize"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"2","md":"2"}},[_c(VTextField,{attrs:{"label":"Font size"},model:{value:(_vm.item.fontSize),callback:function ($$v) {_vm.$set(_vm.item, "fontSize", $$v)},expression:"item.fontSize"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"2","md":"2"}},[_c(VTextField,{attrs:{"label":"cols"},model:{value:(_vm.item.cols),callback:function ($$v) {_vm.$set(_vm.item, "cols", $$v)},expression:"item.cols"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <!-- Header Section -->
    <div>
      <div class="d-print-block d-flex justify-space-between" v-if="!editHeader">
        <span class="my-auto" v-if="signatureHeader">{{ signatureHeader }}</span>
        <span class="d-print-none my-auto" v-else>N/A</span>
        <v-btn class="ml-4 d-print-none" @click="editHeader = true" icon small>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <div v-if="editHeader">
        <v-text-field
          v-model="signatureHeader"
          label="Signature header"
          @click:append-outer="saveHeader"
          append-outer-icon="mdi-check"
        />
      </div>
    </div>

    <!-- Signature Pad Section -->
    <div @click="openSignMode" class="d-flex justify-center signature-pad my-2">
      <div v-if="signatureSvg" style="position: absolute; margin-top: -30px;">
        <v-img
          contain
          :aspect-ratio="16 / 9"
          max-height="100px"
          max-width="200px"
          :src="signatureSvg"
        />
      </div>
      <div v-else class="text-center font-weight-thin my-auto mx-10 d-print-none">
        Sign Here <v-icon>mdi-draw-pen</v-icon>
      </div>
    </div>

    <!-- Footer Section -->
    <div>
      <div class="d-print-block d-flex justify-space-between" v-if="!editFooter">
        <div v-if="signatureFooter" class="d-flex justify-space-between">
          <span>( {{ signatureFooter }} )</span>
          <span class="ml-2" v-if="signature">{{ signature.updatedDate | formatDateYear }}</span>
        </div>
        <span class="d-print-none" v-else>N/A</span>
        <v-btn class="ml-4 d-print-none" @click="editFooter = true" icon small>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <div v-if="editFooter">
        <v-text-field
          v-model="signatureFooter"
          label="Signature footer"
          @click:append-outer="saveFooter"
          append-outer-icon="mdi-check"
        />
      </div>
    </div>

    <!-- Signature Dialog -->
    <v-dialog
      v-resize="onResize"
      style="zIndex: 1000"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="signMode"
      persistent
    >
      <v-card class="pt-5">
        <v-card-subtitle class="d-flex">
          <div class="my-auto">Sign Below</div>
          <v-spacer />
          <v-btn @click="closeDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-subtitle>

        <v-card-text class="px-4">
          <v-sheet :elevation="2">
            <vue-signature
              :key="windowSize.x"
              v-if="loaded"
              :h="`${(windowSize.x / 3) < 200 ? 200 : windowSize.x / 3}px`"
              :w="`${windowSize.x * 0.8 < 300 ? 300 : windowSize.x * 0.8}px`"
              ref="signaturePad"
              :sigOption="option"
              :defaultUrl="tempSignatureSvg"
              @beginStroke="onBeginStroke"
            ></vue-signature>
          </v-sheet>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn class="ma-2" @click="saveSignature">
            <v-icon class="mr-2">mdi-content-save</v-icon>
            <span>Done</span>
          </v-btn>
          <v-btn class="ma-2" @click="clearSignature">
            <v-icon class="mr-2">mdi-eraser</v-icon>
            <span>Clear</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueSignature from "vue-signature";

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    signature: {
      type: Object,
      required: false,
    },
    signatureHeader: {
      type: String,
      required: false,
    },
    signatureFooter: {
      type: String,
      required: false,
    },
  },
  components: {
    VueSignature,
  },
  data: () => ({
    signMode: false,
    signatureSvg: null, // Current signature displayed
    originalSignatureSvg: null, // Original signature before editing
    tempSignatureSvg: null, // Temporary signature during editing
    signatureId: null,
    windowSize: {},
    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgb(255,255,255)",
      onEnd: null,
    },
    loaded: false,
    editFooter: false,
    editHeader: false,
    signatureModified: false,
  }),
  mounted() {
    if (this.value) {
      this.signatureSvg = this.value;
      this.tempSignatureSvg = this.value;
      this.signatureModified = false;
    }
    this.signatureId = this.signature ? this.signature.id : null;
    this.option.onEnd = this.onEnd;
    this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    this.loaded = true;
  },
  methods: {
    onBeginStroke() {
      this.signatureModified = true;
    },
    onEnd() {
      // Optional: Handle end of stroke if needed
    },
    clearSignature() {
      if (this.$refs.signaturePad) {
        this.$refs.signaturePad.clear();
      }
      this.tempSignatureSvg = null;
      this.signatureModified = true;
    },
    // Method to open the signature dialog
    openSignMode() {
      if (this.signatureSvg) {
        this.originalSignatureSvg = this.signatureSvg; // Save original
        this.tempSignatureSvg = null; // Clear for new signature
        if (this.$refs.signaturePad) {
          this.$refs.signaturePad.clear(); // Clear the signature pad
        }
      } else {
        this.originalSignatureSvg = null;
        this.tempSignatureSvg = null;
      }
      this.signMode = true; // Open dialog
    },
    // Method to close the dialog and revert if needed
    closeDialog() {
      if (this.originalSignatureSvg) {
        this.tempSignatureSvg = this.originalSignatureSvg; // Revert to original
      }
      this.signMode = false; // Close dialog
    },
    // Method to save the new signature
    saveSignature() {
      if (this.$refs.signaturePad) {
        const svg = this.$refs.signaturePad.save("image/svg+xml");

        // If the signature pad is empty, revert to original
        if(!this.signatureModified){
          this.signatureSvg = this.originalSignatureSvg;
          this.$emit("input", this.signatureSvg);
        }
        else if (svg && svg.trim() !== "") {
          this.signatureSvg = svg;
          this.tempSignatureSvg = svg;
          this.originalSignatureSvg = svg; // Update original with new signature
          this.$emit("input", this.signatureSvg);

          const params = {
            id: this.signatureId,
            signature: this.signatureSvg,
            signatureHeader: this.signatureHeader,
            signatureFooter: this.signatureFooter,
          };
          this.$axios
            .put(`/worksheetSignature/${this.signatureId}`, params)
            .then((resp) => {
              console.log("Save Signature Response:", resp);
              this.$emit("done", this.signatureSvg);
            })
            .catch((error) => {
              console.error("Error Saving Signature:", error);
            });
        }
      }
      this.signMode = false; // Close dialog
      this.signatureModified = false;
    },
    // Method to save the header without affecting the signature
    saveHeader() {
      this.editHeader = false;
      const params = {
        id: this.signatureId,
        signature: this.signatureSvg,
        signatureHeader: this.signatureHeader,
        signatureFooter: this.signatureFooter,
      };
      this.$axios
        .put(`/worksheetSignature/${this.signatureId}`, params)
        .then((resp) => {
          console.log("Save Header Response:", resp);
          this.$emit("done", this.signatureSvg);
        })
        .catch((error) => {
          console.error("Error Saving Header:", error);
        });
    },
    // Method to save the footer without affecting the signature
    saveFooter() {
      this.editFooter = false;
      const params = {
        id: this.signatureId,
        signature: this.signatureSvg,
        signatureHeader: this.signatureHeader,
        signatureFooter: this.signatureFooter,
      };
      this.$axios
        .put(`/worksheetSignature/${this.signatureId}`, params)
        .then((resp) => {
          console.log("Save Footer Response:", resp);
          this.$emit("done", this.signatureSvg);
        })
        .catch((error) => {
          console.error("Error Saving Footer:", error);
        });
    },
    undo() {
      if (this.$refs.signaturePad) {
        this.$refs.signaturePad.undo();
      }
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
};
</script>

<style scoped>
.signature-pad {
  width: 100%;
  max-height: 100px;
  min-width: 100px;
  min-height: 50px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  position: relative; /* Ensure the signature image is positioned correctly */
}
</style>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{staticStyle:{"z-index":"1000"},attrs:{"width":"80%","fullscreen":_vm.$vuetify.breakpoint.xsOnly,"align":"top"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',_vm.$attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.activatorIcon ? _vm.activatorIcon : "mdi-qrcode-scan"))])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,[_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.label.scanQR'))+" ")]),(_vm.cameras && _vm.cameras.length > 0)?_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-camera")])],1)]}}],null,false,1532243740)},[_c(VList,_vm._l((_vm.cameras),function(camera){return _c(VListItem,{key:camera.id,on:{"click":function($event){return _vm.switchCamera(camera)}}},[_c(VListItemTitle,[_vm._v(_vm._s(camera.label))])],1)}),1)],1):_vm._e(),(!_vm.cameras || _vm.cameras.length < 1)?_c(VBtn,{on:{"click":_vm.getCameraPermission}},[_c(VIcon,[_vm._v("mdi-camera")])],1):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c(VCardText,{staticClass:"mt-5"},[_c('div',{attrs:{"id":"qrScanner"}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VToolbar,{staticClass:"mb-8 mt-4 elevation-1",attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.title))]})],2),_c(VSpacer),_c('div',{staticClass:"text-center d-print-none"},[_vm._t("menu",function(){return [(_vm.menuItems && _vm.menuItems.length > 0)?_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.menuTitle))])]}}],null,false,3258747318)},[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"400","width":"200","tile":""}},[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},_vm._l((_vm.menuItems),function(item,i){return _c(VListItem,{key:i,attrs:{"disabled":item.disabled ? true : false,"to":item.to},on:{"click":function($event){return _vm.clickedMenuItem($event, item)}}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1)],1):_vm._e()]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c(VDialog,{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v(_vm._s(_vm.activatorIcon ? _vm.activatorIcon : "mdi-folder-open-outline"))])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto pa-5",attrs:{"width":"100%"}},[_c(VCardTitle,[_c('span',[_vm._v("Load Map")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VSelect,{attrs:{"items":_vm.maps,"label":"Choose a Map","item-text":"name","item-value":"id","return-object":"","clearable":""},model:{value:(_vm.selectedMap),callback:function ($$v) {_vm.selectedMap=$$v},expression:"selectedMap"}})],1),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{on:{"click":_vm.chooseMap}},[_vm._v("Start")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VFileInput,{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"file",attrs:{"label":"Choose Images","outlined":"","dense":"","capture":"camera","accept":"image/*","prepend-icon":"mdi-camera","clearable":"","hide-details":"","multiple":"","loading":_vm.loading,"disabled":_vm.disabled},on:{"change":_vm.filesChanged},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c(VSheet,{staticClass:"d-flex mx-auto"},[_c(VSlideGroup,{attrs:{"multiple":"","show-arrows":""}},[_c(VSlideItem,{key:0,staticClass:"my-auto"},[_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"mx-2",attrs:{"ripple":"","depressed":"","rounded":"","icon":""},on:{"click":_vm.chooseFile}},[_c(VIcon,{attrs:{"color":"primary","x-large":""}},[_vm._v("mdi-camera-outline")])],1),(_vm.previewImages && _vm.previewImages.length > 0)?_c('div',{staticClass:"text-caption font-weight-light mx-2"},[_c('div',[_vm._v(" "+_vm._s(`${_vm.previewImages.length} files`)+" ")]),_c('div',[_vm._v(" "+_vm._s(` ${_vm.totalSize}/${_vm.fileSize(_vm.maxSize)} max`)+" ")])]):_vm._e()],1)]),_vm._l((_vm.previewImages),function(img,index){return _c(VSlideItem,{key:index + 1},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"my-auto",staticStyle:{"min-height":"100px"}},[_c(VImg,{staticClass:"ma-2",attrs:{"contain":"","max-height":"80","max-width":"150","src":img},on:{"click":function($event){_vm.selectedImg = img;
                _vm.imgDialog = true;}}})],1),_c(VBtn,{attrs:{"disabled":_vm.loading,"icon":""},on:{"click":function($event){return _vm.remove(index)}}},[_c(VIcon,[_vm._v("mdi-delete-outline")])],1)],1)])})],2)],1),_c(VDialog,{staticStyle:{"z-index":"1000"},model:{value:(_vm.imgDialog),callback:function ($$v) {_vm.imgDialog=$$v},expression:"imgDialog"}},[(_vm.selectedImg)?_c(VCard,[_c(VCardTitle,{staticClass:"justify-end"},[_c('div',[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.imgDialog = null}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)]),_c(VCardText,{staticClass:"d-flex"},[_c(VImg,{staticClass:"ma-2",attrs:{"contain":"","width":"80%","src":_vm.selectedImg}})],1)],1):_c('div',{staticClass:"text-subtitle-2"},[_vm._v("No images selected")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
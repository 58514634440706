import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VMenu,{attrs:{"offset-y":_vm.offset,"close-on-content-click":false,"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"secondary","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-tune")])],1)]}}])},[_c(VList,[_c(VSheet,{staticClass:"ma-5"},[_c('span',[_vm._v("Display Filter")]),_c(VRadioGroup,{attrs:{"label":"Unit","row":""},on:{"change":_vm.changed},model:{value:(_vm.displayOptions.unit),callback:function ($$v) {_vm.$set(_vm.displayOptions, "unit", $$v)},expression:"displayOptions.unit"}},[_c(VRadio,{attrs:{"label":"Value","value":"value"}}),_c(VRadio,{attrs:{"label":"Quantity","value":"quantity"}})],1),_c(VSwitch,{attrs:{"label":`Show product grouping: ${
              _vm.displayOptions.showProductGroup ? 'Yes' : 'No'
          }`},on:{"change":_vm.changed},model:{value:(_vm.displayOptions.showProductGroup),callback:function ($$v) {_vm.$set(_vm.displayOptions, "showProductGroup", $$v)},expression:"displayOptions.showProductGroup"}}),_c(VRadioGroup,{attrs:{"label":"Filter Products","row":""},on:{"change":_vm.changed},model:{value:(_vm.displayOptions.withDeltaOnly),callback:function ($$v) {_vm.$set(_vm.displayOptions, "withDeltaOnly", $$v)},expression:"displayOptions.withDeltaOnly"}},[_c(VRadio,{attrs:{"label":"All products","value":false}}),_c(VRadio,{attrs:{"label":"Products with changes","value":true}})],1),_c(VSwitch,{attrs:{"label":`Show inventory events: ${
              _vm.displayOptions.showEvents ? 'Yes' : 'No'
          }`},on:{"change":_vm.changed},model:{value:(_vm.displayOptions.showEvents),callback:function ($$v) {_vm.$set(_vm.displayOptions, "showEvents", $$v)},expression:"displayOptions.showEvents"}}),_c(VSwitch,{attrs:{"label":`Show products with inventory events only: ${
              _vm.displayOptions.showProductsWithEvents ? 'Yes' : 'No'
          }`},on:{"change":_vm.changed},model:{value:(_vm.displayOptions.showProductsWithEvents),callback:function ($$v) {_vm.$set(_vm.displayOptions, "showProductsWithEvents", $$v)},expression:"displayOptions.showProductsWithEvents"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
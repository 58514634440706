import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g({},{ ...on, ...attrs }),[_vm._v("mdi-dots-vertical")])]}}])},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.clickedMenuItem('new-delivery')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(" mdi-truck-delivery ")])],1),_c(VListItemTitle,[_vm._v("New Delivery")])],1),_c(VListItem,{on:{"click":function($event){return _vm.clickedMenuItem('new-production')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(" mdi-account-hard-hat ")])],1),_c(VListItemTitle,[_vm._v("New Production")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
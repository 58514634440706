import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"grey darken-2"},[_vm._v(" Create contact ")]),_c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"align-center justify-space-between",attrs:{"cols":"12"}},[_c(VRow,{attrs:{"align":"center"}},[_c(VAvatar,{staticClass:"mr-4",attrs:{"size":"40px"}},[_c('img',{attrs:{"src":"//ssl.gstatic.com/s2/oz/images/sge/grey_silhouette.png","alt":""}})]),_c(VTextField,{attrs:{"placeholder":"Name"}})],1)],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"prepend-icon":"business","placeholder":"Company"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"placeholder":"Job title"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"prepend-icon":"mail","placeholder":"Email"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"type":"tel","prepend-icon":"phone","placeholder":"(000) 000 - 0000"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"prepend-icon":"notes","placeholder":"Notes"}})],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"primary"}},[_vm._v("More")]),_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
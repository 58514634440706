import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsDivider } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"worksheetTypeDefinitionCreate"},[_c(VBreadcrumbs,[_c(VBreadcrumbsItem,{attrs:{"exact":true,"to":{ name: 'home', params: {} }}},[_vm._v("Home")]),_c(VBreadcrumbsDivider,[_vm._v("/")]),_c(VBreadcrumbsItem,{attrs:{"exact":true,"to":{ name: 'worksheetTypeDefinition', params: {} }}},[_vm._v(" Worksheet Type Definition ")]),_c(VBreadcrumbsDivider,[_vm._v("/")]),_c(VBreadcrumbsItem,{attrs:{"to":{ name: 'worksheetTypeDefinitionCreate', params: {} }}},[_vm._v(" Worksheet Type Definition ")])],1),_c(VCard,[_c(VCardTitle,[_vm._v("Worksheet Type Definition")]),_c(VCardText,[_c('worksheetTypeDefinition-form')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
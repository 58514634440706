import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container index text-center"},[(_vm.loading || _vm.dynamicQrCode)?_c('div',{staticClass:"d-flex flex-column ma-5 text-center"},[_c(VProgressCircular,{staticClass:"mx-auto my-4",attrs:{"size":50,"color":"primary","indeterminate":""}}),_c('div',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(_vm.executionStatus))])],1):_vm._e(),(!_vm.loading && !_vm.dynamicQrCode)?_c('div',[_c(VAlert,{staticClass:"ma-5",attrs:{"outlined":"","type":"error"}},[_vm._v("Invalid QR Code ")])],1):_vm._e(),_c(VDialog,{attrs:{"max-width":"350"},model:{value:(_vm.requireAccessCode),callback:function ($$v) {_vm.requireAccessCode=$$v},expression:"requireAccessCode"}},[_c(VCard,[_c('v-card-content',[_c(VCardText,[_c('div',{staticClass:"text-subtitle2"},[_vm._v(" You are trying to access a protected resource, please enter your access code to proceed. ")]),_c(VTextField,{staticClass:"mt-5",attrs:{"label":"Access Code"},model:{value:(_vm.accessCode),callback:function ($$v) {_vm.accessCode=$$v},expression:"accessCode"}})],1)],1),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{staticClass:"ma-5",on:{"click":function($event){return _vm.validateAccessCode()}}},[_vm._v("Confirm")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
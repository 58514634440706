import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Reset Password ")]),_c(VCardText,[_c(VTextField,{attrs:{"type":"password","label":"Current Password"},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}}),_c(VTextField,{attrs:{"type":"password","label":"New Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VTextField,{attrs:{"type":"password","label":"Confirm New Password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"disabled":_vm.isAppLoading},on:{"click":function($event){return _vm.resetPassword()}}},[_vm._v("Update")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
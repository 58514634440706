import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{staticClass:"d-print-none",staticStyle:{"z-index":"999"},attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","color":"primary darken-3","dark":""}},[_c(VProgressLinear,{attrs:{"active":_vm.isAppLoading,"indeterminate":true,"absolute":"","top":true,"color":"accent darken-1"}}),_c(VToolbarTitle,{staticClass:"ml-0 pl-4",staticStyle:{"width":"300px"}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleDrawer.apply(null, arguments)}}}),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v(_vm._s(_vm.companyName))])],1),_c(VSpacer),(_vm.currentUser != null)?_c(VBtn,{staticClass:"d-none",attrs:{"data-cy":"securemenu","color":"primary","dark":""}},[_vm._v(_vm._s(_vm.currentUser.firstName))]):_vm._e(),_c(VBtn,{attrs:{"dark":"","icon":"","to":{ name: 'inventorySearch' }},on:{"click":_vm.hideDrawer}},[_c(VIcon,[_vm._v("mdi-folder-search-outline")])],1),_c('qr-scanner'),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-3",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VChip,{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.language))])],1)]}}])},[_c(VList,_vm._l((_vm.languages),function(item){return _c(VListItem,{key:item.text,on:{"click":function($event){$event.stopPropagation();return _vm.changeLanguage(item.value)}}},[_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)}),1)],1),(!_vm.isLoggedIn)?_c(VBtn,{staticClass:"ml-3",attrs:{"data-cy":"login","to":"/login","icon":"","title":"Login"}},[_c(VIcon,[_vm._v("mdi-login")])],1):_vm._e(),(_vm.isLoggedIn)?_c(VBtn,{staticClass:"ml-3",attrs:{"icon":"","title":"Logout"},on:{"click":_vm.logout}},[_c(VIcon,[_vm._v("mdi-logout")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
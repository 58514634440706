import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.isEditMode ? "Edit Delivery" : "New Delivery"))]),_c(VCardSubtitle,[_vm._v(" "+_vm._s(_vm.product ? _vm.product.name : "Product N/A")+" ")]),_c(VCardText,[(_vm.customers)?_c(VAutocomplete,{staticStyle:{"z-index":"100"},attrs:{"clearable":"","label":"Customer","items":_vm.customers,"item-text":"name","item-value":"id"},model:{value:(_vm.delivery.customerId),callback:function ($$v) {_vm.$set(_vm.delivery, "customerId", $$v)},expression:"delivery.customerId"}}):_vm._e(),_c(VTextField,{attrs:{"label":"Quantity","type":"number"},model:{value:(_vm.delivery.quantity),callback:function ($$v) {_vm.$set(_vm.delivery, "quantity", $$v)},expression:"delivery.quantity"}}),_c('date-picker',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.base.startDate'),"defaultNowOnNull":false},model:{value:(_vm.delivery.start),callback:function ($$v) {_vm.$set(_vm.delivery, "start", $$v)},expression:"delivery.start"}}),_c('date-picker',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.base.endDate'),"defaultNowOnNull":false},model:{value:(_vm.delivery.end),callback:function ($$v) {_vm.$set(_vm.delivery, "end", $$v)},expression:"delivery.end"}}),_c(VTextarea,{attrs:{"label":"Memo","placeholder":"Optional","rows":2,"auto-grow":"","outlined":""},model:{value:(_vm.delivery.memo),callback:function ($$v) {_vm.$set(_vm.delivery, "memo", $$v)},expression:"delivery.memo"}})],1),_c(VCardActions,{staticClass:"d-flex justify-space-between"},[_c('div',[_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.isEditMode ? "Update" : "Save"))]),_c(VBtn,{staticClass:"ml-2",attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")])],1),(_vm.isEditMode)?_c(VBtn,{attrs:{"color":"warning"},on:{"click":_vm.remove}},[_vm._v(" Delete ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
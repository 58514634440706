import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsDivider } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view"},[_c(VBreadcrumbs,[_c(VBreadcrumbsItem,{attrs:{"exact":true,"to":{ name: 'home', params: {} }}},[_vm._v("Home")]),_c(VBreadcrumbsDivider,[_vm._v("/")]),_c(VBreadcrumbsItem,{attrs:{"exact":true,"to":{ name: 'accountClosing', params: {} }}},[_vm._v(" Account Closing ")]),_c(VBreadcrumbsDivider,[_vm._v("/")]),_c(VBreadcrumbsItem,{attrs:{"to":{ name: 'accountClosingView', params: {} }}},[_vm._v(" View Account Closing ")])],1),(_vm.loaded)?_c(VCard,[_c(VCardTitle,[_vm._v(" View Account Closing ")]),_c(VCardText,[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[(_vm.accountClosing.id)?_c('tr',[_c('td',{staticClass:"text-subtitle-1",attrs:{"max-width":"35%"}},[_vm._v("ID:")]),_c('td',[_vm._v(_vm._s(_vm.accountClosing.id))])]):_vm._e(),(_vm.accountClosing.closingDate)?_c('tr',[_c('td',{staticClass:"text-subtitle-1",attrs:{"max-width":"35%"}},[_vm._v("Closed Date:")]),_c('td',[_vm._v(_vm._s(_vm.accountClosing.closingDate))])]):_vm._e(),(_vm.accountClosing.adminUserId)?_c('tr',[_c('td',{staticClass:"text-subtitle-1",attrs:{"max-width":"35%"}},[_vm._v("By User:")]),_c('td',[_vm._v(_vm._s(_vm.accountClosing.adminUserId))])]):_vm._e(),(_vm.accountClosing.recordedTime)?_c('tr',[_c('td',{staticClass:"text-subtitle-1",attrs:{"max-width":"35%"}},[_vm._v("Created On:")]),_c('td',[_vm._v(_vm._s(_vm.accountClosing.recordedTime))])]):_vm._e()])]},proxy:true}],null,false,3950525921)})],1)],1):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between ma-5"},[_c(VBtn,{on:{"click":_vm.goBack}},[_vm._v("Back")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.edit(_vm.id)}}},[_vm._v("Edit")]),_c('span')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./FlowRules.vue?vue&type=template&id=77b4e43b&scoped=true"
import script from "./FlowRules.vue?vue&type=script&lang=js"
export * from "./FlowRules.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77b4e43b",
  null
  
)

export default component.exports
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,[_c('div',{staticClass:"d-flex justify-space-around flex-column flex-lg-row"},[_c('div',[_c('div',{staticClass:"text-subtitle-2 ma-2 text-center font-weight-light"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.productionInput"))+" "),_c(VBtn,{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){_vm.toggleLinear1 = !_vm.toggleLinear1}}},[(_vm.toggleLinear1)?_c(VIcon,[_vm._v(" mdi-chart-line ")]):_c(VIcon,[_vm._v(" mdi-chart-bell-curve-cumulative ")])],1)],1),_c(VSheet,{staticClass:"pa-3 d-flex justify-center overflow-y-auto",attrs:{"elevation":2,"height":400}},[(_vm.inputData && _vm.inputData.labels && _vm.inputData.labels.length > 0)?_c('bar-chart',{staticClass:"mb-6",attrs:{"show-legend":false,"showTitle":true,"title":`Total Input ${_vm.totalInput}`,"data":_vm.inputData,"horizontal":"","logarithmic":_vm.toggleLinear1,"responsive":true,"maintainAspectRatio":false,"height":_vm.calculateChartHeight(_vm.inputData.labels.length)},on:{"click":function($event){return _vm.showDetails($event, { processDirection: 'input' })}}}):_c('div',{staticClass:"text-center text-caption font-light-weight"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.noDataText"))+" ")])],1)],1),_c('div',[_c('div',{staticClass:"text-subtitle-2 ma-2 text-center font-weight-light"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.productionOutput"))+" "),_c(VBtn,{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){_vm.toggleLinear2 = !_vm.toggleLinear2}}},[(_vm.toggleLinear2)?_c(VIcon,[_vm._v(" mdi-chart-line ")]):_c(VIcon,[_vm._v(" mdi-chart-bell-curve-cumulative ")])],1)],1),_c(VSheet,{staticClass:"pa-3 d-flex justify-center overflow-y-auto",attrs:{"elevation":2,"height":400}},[(
            _vm.outputData && _vm.outputData.labels && _vm.outputData.labels.length > 0
          )?_c('bar-chart',{staticClass:"mb-6",attrs:{"show-legend":false,"showTitle":true,"title":`Total Output ${_vm.totalOutput}`,"data":_vm.outputData,"horizontal":"","logarithmic":_vm.toggleLinear2,"responsive":true,"maintainAspectRatio":false,"height":_vm.calculateChartHeight(_vm.outputData.labels.length)},on:{"click":function($event){return _vm.showDetails($event, { processDirection: 'output' })}}}):_c('div',{staticClass:"text-center text-caption font-light-weight"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.noDataText"))+" ")])],1)],1)]),_c(VDialog,{staticStyle:{"z-index":"1000"},attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.showDetailsDialog),callback:function ($$v) {_vm.showDetailsDialog=$$v},expression:"showDetailsDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"justify-end"},[_c('div',[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.showDetailsDialog = null}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)]),_c(VCardText,[_c('daily-distribution-chart',{attrs:{"type":_vm.processDirection,"product":_vm.product,"startDate":_vm.startDate,"endDate":_vm.endDate}}),(_vm.showDetailsDialog)?_c('infinite-scroll',{key:_vm.infiniteScrollKey,attrs:{"totalPages":_vm.totalPages},on:{"fetchData":_vm.fetchData,"update:totalPages":function($event){_vm.totalPages=$event},"update:total-pages":function($event){_vm.totalPages=$event}}},[(_vm.worksheets && _vm.worksheets.length > 0)?_c('div',{staticClass:"d-flex justify-center text-subtitle-1 mb-2"},[_c('div',{staticClass:"ml-2 text-caption"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.label.foundX", _vm.totalSizes ? _vm.totalSizes : 0 ))+" ")])]):_vm._e(),_c(VDivider),_vm._l((_vm.worksheets),function(worksheet,index){return _c('worksheet-card',{key:worksheet ? worksheet.id : 'null',attrs:{"worksheet":worksheet,"index":index + 1}})})],2):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-sheet class="mx-5">
    <transition name="fade">
      <router-view @showParent="showParent"> </router-view>
    </transition>

    <div v-if="showParentPage">
      <!-- Breadcrumbs -->
      <v-breadcrumbs>
        <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }">
          Home
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
        <v-breadcrumbs-item
          :exact="true"
          :to="{ name: 'inventoryLabResult', params: {} }"
          active
        >
          Inventory Lab Result
        </v-breadcrumbs-item>
      </v-breadcrumbs>

      <!-- Date Range Picker Section -->
      <div class="d-flex flex-column flex-lg-row mb-3">
        <!-- Start Date Picker -->
        <v-menu
          ref="startMenu"
          v-model="startMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
          z-index="100"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Start Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            @input="startMenu = false"
            :max="endDate"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="startMenu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.startMenu.save(startDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <!-- End Date Picker -->
        <v-menu
          ref="endMenu"
          v-model="endMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
          class="mx-2"
          z-index="100"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              label="End Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            @input="endMenu = false"
            :min="startDate"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="endMenu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.endMenu.save(endDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
      <!-- End of Date Range Picker Section -->

      <!-- Search and Action Buttons -->
      <div class="d-flex flex-column flex-lg-row">
        <v-text-field
          dense
          v-model="searchTerms"
          @keyup.enter="search"
          @click:clear="clearSearch"
          @click:append="search"
          placeholder="Press enter to begin search"
          append-icon="mdi-magnify"
          outlined
          clearable
          label="Search"
        />
        <div class="d-flex justify-space-around">
          <v-btn
            class="ml-0 ml-lg-4 mb-2 mb-lg-0 align-self-end align-self-lg-start"
            color="primary"
            :to="{ name: 'inventoryLabResultCreate', params: {} }"
          >
            New Lab Result
          </v-btn>
          <v-btn
            class="ml-0 ml-lg-4 mb-2 mb-lg-0 align-self-end align-self-lg-start"
            color="primary"
            :to="{ name: 'inventoryLabResultUpload', params: {} }"
          >
            Upload
          </v-btn>
        </div>
      </div>

      <div class="d-flex flex-column flex-lg-row">
        <!-- Submit Button -->
        <v-btn color="primary" @click="applyDateRange" class="mt-2 mt-md-0">
          Submit
        </v-btn>
      </div>
      <v-divider class="my-2" />

      <!-- Display Switches and Submit & Download Buttons -->
      <div class="d-flex justify-space-around flex-column flex-md-row align-items-center">
        <div class="d-flex align-items-center">
          <div class="font-weight-light mr-4 mt-1">Display</div>
          <v-switch
            @change="search"
            class="mt-0 mx-2"
            :value="true"
            label="Unassigned Only"
            v-model="unassignedOnly"
          />
          <v-switch
            @change="search"
            class="mt-0 mx-2"
            :value="true"
            label="Final Test Only"
            v-model="finalTestOnly"
          />
        </div>
        
        <!-- Download All Results Button with Loading State -->
        <v-btn
          color="secondary"
          class="ml-4 mt-2 mt-md-0"
          @click="downloadAll"
          :loading="isDownloading"
        >
          Download All Results
        </v-btn>
      </div>

      <!-- Data Table -->
      <div class="table-container">
        <div class="d-flex justify-end my-2"></div>
        <v-data-table
          id="lab-results-table"
          :headers="headers"
          :items="inventoryLabResultList"
          :options.sync="options"
          :items-per-page="pagination.pageSize"
          :loading="!inventoryLabResultProcessed"
          :server-items-length="pagination.totalSize"
          :page="pagination.current"
          item-key="id"
          class="elevation-1"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50, 100, 500]
          }"
          @update:options="pageChanged"
        >
          <template v-slot:item.inventoryId="{ item }">
            <a
              v-if="item.inventoryId"
              @click="
                lookupInventory = true;
                selectedRow = item;
                selectedInventory = item.inventoryId;
              "
              >{{ item.inventoryId }}</a
            >
            <a
              v-else
              style="white-space: nowrap"
              @click="
                lookupInventory = true;
                selectedRow = item;
              "
              >Assign</a
            >
          </template>
          <template v-slot:item.testTime="{ item }">
            <a style="white-space: nowrap" @click="view(item)">
              {{ item.testTime | formatDateTimeYear }}
            </a>
          </template>
          <template v-slot:item.finalTest="{ item }">
            <v-icon v-if="item.finalTest">mdi-check</v-icon>
            <v-icon v-else>mdi-close</v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <div style="white-space: nowrap">
              <v-icon small class="mr-2" @click="edit(item)">
                mdi-pencil
              </v-icon>
              <v-icon small class="mr-2" @click="remove(item)">
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>

        <div class="my-5">
          <router-link :to="{ name: 'inventoryCoaCheck', params: {} }">
            Find inventory without CoA
          </router-link>
        </div>
      </div>
    </div>

    <!-- Confirmation and Lookup Components -->
    <confirmation-dialog ref="confirm" />
    <inventory-lookup
      :activated.sync="lookupInventory"
      v-model="selectedInventory"
      @selected="assignInventory"
    />
  </v-sheet>
</template>

<script>
import { json2excel } from "js2excel";
import { mapActions, mapGetters } from "vuex";
const InventoryLookup = () =>
  import("@/components/inventory/InventoryLookup.vue");

export default {
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 100,
      },
      inventoryLabResultList: [],
      allInventoryLabResultList: [], // For Download All
      showParentPage: true,
      lookupInventory: false,
      selectedInventory: null,
      selectedRow: null,
      elements: [],
      options: {
        sortBy: ["testTime"],
        sortDesc: [true],
      },
      headers: [],
      baseHeaders: [
        {
          text: "Inventory ID",
          value: "inventoryId",
          align: "start",
          sortable: true,
        },
        {
          text: "Test Time",
          value: "testTime",
          align: "start",
          sortable: true,
        },
        {
          text: "Sample Name",
          value: "sampleName",
          align: "start",
          sortable: true,
        },
        {
          text: "Quality",
          value: "quality",
          align: "start",
          sortable: true,
        },
        {
          text: "AveroutN",
          value: "averoutN",
          align: "start",
          sortable: true,
        },
        {
          text: "Program",
          value: "program",
          align: "start",
          sortable: true,
        },
        {
          text: "Technician",
          value: "technician",
          align: "start",
          sortable: true,
        },
        {
          text: "Final",
          value: "finalTest",
          align: "start",
          sortable: true,
        },
      ],
      sort: {
        key: null,
      },
      searchTerms: "",
      unassignedOnly: false,
      finalTestOnly: false,

      // New Data Properties for Date Range
      startMenu: false,
      endMenu: false,
      startDate: null,
      endDate: null,

      // Loading State for Download All
      isDownloading: false,
      errorMsg: "Error downloading data",
    };
  },
  components: {
    InventoryLookup,
  },
  filters: {
    negativeNumber: function (value) {
      if (value && value > 0) {
        return value;
      } else if (value < 0) {
        return `<${Math.abs(value)}`;
      } else {
        return 0;
      }
    },
    formatDateTimeYear(value) {
      if (!value) return "";
      const date = new Date(value);
      return date.toLocaleString("default", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
  },
  computed: {
    ...mapGetters("inventoryLabResult", [
      "inventoryLabResultProcessed",
      "getInventoryLabResults",
    ]),
    canDownloadAll() {
      // Enable Download All button only if there are results to download
      return this.allInventoryLabResultList.length > 0;
    },
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("inventoryLabResult", [
      "deleteInventoryLabResult",
      "fetchInventoryLabResults",
      "updateInventoryLabResult",
    ]),
    view(item) {
      this.$router.push({
        name: "inventoryLabResultView",
        params: { id: item.id },
      });
    },
    edit(item) {
      this.$router.push({
        name: "inventoryLabResultEdit",
        params: { id: item.id },
      });
    },
    async remove(item) {
      let confirm = await this.$refs.confirm.open(
        "Confirm Delete",
        "Do you want to delete this inventory lab result?"
      );
      if (confirm) {
        this.deleteInventoryLabResult(item.id)
          .then(() => {
            this.addSuccessMessage("Inventory lab result deleted");
            this.getResources();
          })
          .catch(() => {
            this.addErrorMessage("Server responded with an error.");
          });
      }
    },
    getResources() {
      const sortBy =
        this.options["sortBy"] && this.options["sortBy"].length > 0
          ? this.options.sortBy[0]
          : "createTime";
      const sortDesc =
        this.options["sortDesc"] && this.options["sortDesc"].length > 0
          ? this.options.sortDesc[0]
          : true;

      // Prepare date parameters as YYYY-MM-DD without time
      const startDate = this.startDate ? this.startDate : null;
      const endDate = this.endDate ? this.endDate : null;

      const params = {
        page:
          this.pagination.current && this.pagination.current > 0
            ? this.pagination.current - 1
            : 0,
        size: this.pagination.pageSize,
        sort: `${sortBy}${sortDesc ? ",desc" : ""}`,
        searchTerm: this.searchTerms,
        unassignedOnly: this.unassignedOnly,
        finalTest: this.finalTestOnly ? true : null,
        startTime: startDate, // Send as 'YYYY-MM-DD'
        endTime: endDate,     // Send as 'YYYY-MM-DD'
      };

      this.fetchInventoryLabResults(params)
        .then((response) => {
          if (response) {
            this.pagination.totalSize = response.totalElements;
            if (this.pagination.totalSize > 0) {
              this.inventoryLabResultList = response.content;
              this.prepareElementHeaders(
                this.inventoryLabResultList.length > 0
                  ? this.inventoryLabResultList[0].elements
                  : []
              );

              this.inventoryLabResultList.forEach((item) => {
                item.elements.forEach((element) => {
                  item[element.element.symbol] = element.compositionPercent;
                });
              });
            } else {
              this.inventoryLabResultList = [];
            }
          }
        })
        .catch((error) => {
          this.addErrorMessage("Server responded with an error: " + error);
        });
    },
    showParent(show) {
      this.showParentPage = show;
    },
    pageChanged(pagination) {
      this.pagination.current = pagination.page;
      this.pagination.pageSize = pagination.itemsPerPage;

      this.getResources();
    },
    assignInventory(inventory) {
      const updateLabResult = this.selectedRow;
      updateLabResult.inventoryId = inventory.id;
      this.updateInventoryLabResult(updateLabResult)
        .then(() => {
          this.addSuccessMessage(
            "Inventory assigned to " + updateLabResult.sampleName
          );
          this.getResources();
        })
        .catch(() => {
          this.addErrorMessage("Server responded with an error.");
        });
    },
    search() {
      this.getResources();
    },
    clearSearch() {
      this.searchTerms = "";
      this.getResources();
    },
    prepareElementHeaders(elements) {
      this.elements = [];
      const headers = [...this.baseHeaders];

      if (elements && elements.length > 0) {
        elements.forEach((element) => {
          headers.push({
            text: element.element.symbol,
            value: element.element.symbol,
            align: "start",
            sortable: false,
          });
        });

        this.elements = elements.map((e) => e.element.symbol);
        headers.push({
          text: "Actions",
          value: "actions",
          align: "end",
          sortable: false,
        });
      }

      this.headers = headers;
    },
    // Method to Calculate Current Quarter Dates
    calculateCurrentQuarterDates() {
      const now = new Date();
      const currentMonth = now.getMonth() + 1; // getMonth is 0-based
      const currentQuarter = Math.floor((currentMonth - 1) / 3) + 1;

      let startMonth = (currentQuarter - 1) * 3 + 1;
      let endMonth = startMonth + 2;

      const startDate = new Date(now.getFullYear(), startMonth - 1, 1);
      const endDate = new Date(now.getFullYear(), endMonth, 0); // Last day of the endMonth

      // Format dates to YYYY-MM-DD
      const formatDate = (date) => {
        const yyyy = date.getFullYear();
        const mm = ("0" + (date.getMonth() + 1)).slice(-2);
        const dd = ("0" + date.getDate()).slice(-2);
        return `${yyyy}-${mm}-${dd}`;
      };

      this.startDate = formatDate(startDate);
      this.endDate = formatDate(endDate);
    },
    // Method to Apply Date Range
    applyDateRange() {
      if (this.startDate && this.endDate) {
        // Validate date range
        if (new Date(this.startDate) > new Date(this.endDate)) {
          this.addErrorMessage("Start Date must be before End Date.");
          return;
        }
        this.getResources();
      } else {
        this.addErrorMessage("Both Start Date and End Date must be selected.");
      }
    },
    // Method to Download All Results with Loading State
    async downloadAll() {
      this.isDownloading = true; // Start loading

      try {
        const sortBy =
          this.options["sortBy"] && this.options["sortBy"].length > 0
            ? this.options.sortBy[0]
            : "createTime";
        const sortDesc =
          this.options["sortDesc"] && this.options["sortDesc"].length > 0
            ? this.options.sortDesc[0]
            : true;

        // Prepare date parameters as YYYY-MM-DD without time
        const startDate = this.startDate ? this.startDate : null;
        const endDate = this.endDate ? this.endDate : null;

        const params = {
          page: 0, // Start from first page
          size: 1000000, // Large size to fetch all records
          sort: `${sortBy}${sortDesc ? ",desc" : ""}`,
          searchTerm: this.searchTerms,
          unassignedOnly: this.unassignedOnly,
          finalTest: this.finalTestOnly ? true : null,
          startTime: startDate, // Send as 'YYYY-MM-DD'
          endTime: endDate,     // Send as 'YYYY-MM-DD'
        };

        // Fetch all data based on current filters
        const response = await this.fetchInventoryLabResults(params);

        if (response) {
          const allInventoryLabResultList = response.content.map((item) => {
            // Flatten elements for download if needed
            let flatItem = { ...item };
            if (item.elements && item.elements.length > 0) {
              item.elements.forEach((element) => {
                flatItem[element.element.symbol] = element.compositionPercent;
              });
              delete flatItem.elements;
            }
            return flatItem;
          });

          // Trigger download
          this.downloadExcel(allInventoryLabResultList);
        }
      } catch (error) {
        this.addErrorMessage("Failed to download all results: " + error);
      } finally {
        this.isDownloading = false; // End loading
      }
    },
    // Method to Handle Excel Download
    downloadExcel(data) {
      if (data && data.length > 0) {
        try {
          json2excel({
            data,
            name: 'Inventory-Lab-Results-All',
            formateDate: "yyyy-mm-dd",
          });
          this.addSuccessMessage("Download initiated successfully.");
        } catch (e) {
          this.addErrorMessage(this.errorMsg);
        }
      } else {
        this.addErrorMessage("No data found to download");
      }
    },
  },
  watch: {
    "pagination.numberRegisterForPage": function () {
      this.pagination.current = -1;
    },
    // Watch for changes in allInventoryLabResultList to trigger download
    allInventoryLabResultList(newVal) {
      if (newVal.length > 0) {
        // The Download component will reactively download when allInventoryLabResultList is populated
        // No additional action needed if Download component watches 'value' prop
        // The download is already triggered via ref in downloadAll method
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    const toDepth = to.path.split("/").length;
    const fromDepth = from.path.split("/").length;
    this.showParentPage = toDepth < fromDepth;
    if (this.showParentPage) {
      this.getResources();
    }
    next();
  },
  created() {
    this.calculateCurrentQuarterDates();
    this.getResources();
  },
};
</script>

<style>
#lab-results-table thead th,
#lab-results-table tbody td {
  white-space: nowrap;
}

#lab-results-table thead th:first-child {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  z-index: 9;
  opacity: 0.95;
  background-color: white;
  overflow: hidden;
  text-overflow: ellipsis;
}

#lab-results-table tbody td:first-child {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  z-index: 9;
  opacity: 0.95;
  background-color: white;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<template>
  <div>
    <v-stepper v-model="currentStep" vertical>
      <template v-for="(step, index) in steps">
        <v-stepper-step
          @click="currentStep = index + 1"
          :complete="step.complete"
          :step="index + 1"
        >
          {{ step.name }}
          <small v-if="step.description">{{ step.description }}</small>
        </v-stepper-step>

        <v-stepper-content :step="index + 1" :key="index">
          <component
            v-bind:is="step.component"
            :worksheet.sync="worksheet"
            :productInfo="productInfo"
            :worksheetTypeFields="worksheetTypeFields"
            @updated="handleUpdated($event, index + 1)"
          />
        </v-stepper-content>
      </template>
    </v-stepper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const WorksheetWeightSettings = () =>
  import("@/components/processWorksheet/WorksheetWeightSettings.vue");
const WorksheetSupplier = () =>
  import("@/components/processWorksheet/WorksheetSupplier.vue");
const WorksheetCustomer = () =>
  import("@/components/processWorksheet/WorksheetCustomer.vue");
const WorksheetDocumentNumber = () =>
  import("@/components/processWorksheet/WorksheetDocumentNumber.vue");
const WorksheetLocation = () =>
  import("@/components/processWorksheet/WorksheetLocation.vue");
const WorksheetFields = () =>
  import("@/components/processWorksheet/WorksheetFields.vue");
const WorksheetProductEditor = () =>
  import("@/components/processWorksheet/WorksheetProductEditor.vue");

export default {
  props: {
    worksheet: {
      type: Object,
      required: true,
    },
    worksheetTypeFields: {
      type: Object,
      required: true,
    },
    productInfo: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    steps: [],
    currentStep: 1, // Initialize to first step
  }),
  computed: {
    ...mapGetters("auth", ["currentUser", "userRoles"]),
    isManagement() {
      const permissibleRoles = ["Administrator", "Manager", "Factory"];
      return (
        this.userRoles &&
        this.userRoles.some((role) => permissibleRoles.includes(role))
      );
    },
    hasEntries() {
      return this.worksheet.entries && this.worksheet.entries.length > 0;
    },
    submitted() {
      return (
        this.worksheet &&
        ["SUBMITTED", "USED"].includes(this.worksheet.status)
      );
    },
  },
  components: {
    WorksheetWeightSettings,
    WorksheetSupplier,
    WorksheetCustomer,
    WorksheetDocumentNumber,
    WorksheetLocation,
    WorksheetFields,
    WorksheetProductEditor,
  },
  created() {
    this.generateWorksheetSteps();
  },
  methods: {
    ...mapActions("processWorksheet", [
      "updateProcessWorksheet",
      "deleteProcessWorksheet",
    ]),
    ...mapActions("messages", [
      "addErrorMessage",
      "addSuccessMessage",
    ]),

    // updateWorksheet(worksheet) {
    //   this.updateProcessWorksheet(worksheet);
    //   this.$emit("update:worksheet", worksheet);
    //   this.$emit("updated", worksheet);
    // },
    generateWorksheetSteps() {
      // Reset steps to avoid duplication if this method is called multiple times
      this.steps = [];

      if (this.worksheetTypeFields.hasWeightSettings) {
        const weightSettings = this.$vuetify.lang.t(
          "$vuetify.worksheet.weightSettings"
        );
        this.steps.push({
          name: weightSettings,
          component: "WorksheetWeightSettings",
          complete: false,
        });
      }
      if (this.worksheetTypeFields.hasSupplier) {
        const supplier = this.$vuetify.lang.t("$vuetify.base.supplier");
        this.steps.push({
          name: supplier,
          component: "WorksheetSupplier",
          complete: false,
        });
      }
      if (this.worksheetTypeFields.hasCustomer) {
        const customer = this.$vuetify.lang.t("$vuetify.base.customer");
        this.steps.push({
          name: customer,
          component: "WorksheetCustomer",
          complete: false,
        });
      }
      if (this.worksheetTypeFields.hasDocumentNumber) {
        const documentNumber = this.$vuetify.lang.t(
          "$vuetify.base.documentNumber"
        );
        this.steps.push({
          name: documentNumber,
          component: "WorksheetDocumentNumber",
          complete: false,
        });
      }
      if (
        this.worksheetTypeFields.hasLocation &&
        this.worksheet.userChooseLocation
      ) {
        const location = this.$vuetify.lang.t("$vuetify.worksheet.location");
        this.steps.push({
          name: location,
          component: "WorksheetLocation",
          complete: false,
        });
      }
      if (this.isManagement && this.submitted) {
        const processTime = this.$vuetify.lang.t(
          "$vuetify.worksheet.processTime"
        );
        this.steps.push({
          name: processTime,
          component: "WorksheetFields",
          complete: false,
        });
      }

      if (this.worksheetTypeFields.hasProduct) {
        const editProduct = this.$vuetify.lang.t(
          "$vuetify.worksheet.editProduct"
        );
        this.steps.push({
          name: editProduct,
          component: "WorksheetProductEditor",
          complete: false,
        });
      }
    },
    handleUpdated(updatedWorksheet, stepIndex) {
      // Emit the updated worksheet to the parent component
      this.$emit("update:worksheet", updatedWorksheet);
      // Mark the current step as complete and move to the next step
      this.stepUpdated(stepIndex);
    },
    stepUpdated(index) {
      if (index < this.steps.length) {
        this.currentStep = index + 1; // Fixed typo from =+ to =
        // Mark the step as complete
        this.$set(this.steps[index], "complete", true);
      } else {
        this.$emit("onComplete");
      }
    },
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>

import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.errorMsg)?_c(VAlert,{staticClass:"mx-2",attrs:{"dismissible":"","type":"warning"},model:{value:(_vm.showMsg),callback:function ($$v) {_vm.showMsg=$$v},expression:"showMsg"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e(),_c(VCard,[_c(VDataTable,{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items},scopedSlots:_vm._u([{key:"item.packedTime",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeYear")(item.packedTime))+" ")]}},{key:"item.worksheetId",fn:function({ item }){return [_c('router-link',{attrs:{"target":"_blank","to":{
            name: 'processWorksheet',
            query: { wsId: item.worksheetId },
          }}},[_vm._v(" "+_vm._s(item.worksheetId)+" ")])]}},{key:"item.salesOrderId",fn:function({ item }){return [_c('a',{attrs:{"target":"_blank","href":`${_vm.domain}/office/sales/order/${item.salesOrderId}?view=detail#packagepanel`}},[_vm._v(" "+_vm._s(item.packagingCode)+" ")])]}},{key:"item.actions",fn:function({ item }){return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c(VIcon,[_vm._v(" mdi-delete-outline ")])],1)]}}])})],1),_c('confirmation-dialog',{ref:"confirm"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
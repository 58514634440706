import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsDivider } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c(VBreadcrumbs,[_c(VBreadcrumbsItem,{attrs:{"exact":true,"to":{ name: 'home', params: {} }}},[_vm._v("Home")]),_c(VBreadcrumbsDivider,[_vm._v("/")]),_c(VBreadcrumbsItem,{attrs:{"disabled":""}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.worksheet.scannedWorksheet'))+" "+_vm._s(_vm.worksheet ? _vm.worksheet.id : "")+" ")])],1),(_vm.worksheet)?_c(VSheet,{staticClass:"text-center"},[_c('div',{staticClass:"text-caption font-weight-light ma-4"},[_c('div',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.base.entry'))+" "),(_vm.worksheet.documentNumber)?_c('span',[_vm._v(_vm._s(_vm.worksheet.documentNumber)+"-")]):_vm._e(),_vm._v(_vm._s(_vm.entryIndex + 1))])]),(_vm.entry)?_c('div',{staticClass:"d-flex justify-space-around text-h6"},[_c('div',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.base.netWeight')))]),_c('div',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.entry.netWeight))+" "+_vm._s(_vm.productInfo ? _vm.productInfo.measureWeight : "")+" ")])]):_vm._e(),_c('div',{staticClass:"text-caption mt-2"},[_c('div',[_vm._v(_vm._s(_vm.entry.notes))]),_c('div',[_vm._v(_vm._s(_vm._f("formatDateTime")(_vm.entry.updateTime)))])]),_c('div',{staticClass:"my-4"},[(_vm.images && _vm.images.length > 0)?_c(VCarousel,{attrs:{"height":"auto"}},_vm._l((_vm.images),function(img,i){return _c(VCarouselItem,{key:i,attrs:{"reverse-transition":"fade-transition","transition":"fade-transition"}},[_c(VImg,{attrs:{"contain":"","aspect-ratio":"1.7","src":img.src}}),_c('div',{staticClass:"text-right text-caption ma-2"},[_vm._v(_vm._s(_vm._f("formatDateTime")(img.time)))])],1)}),1):_vm._e()],1),_c(VDivider,{staticClass:"my-2"}),_c('div',{staticClass:"text-subtitle-2 font-weight-light"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.worksheet.worksheet'))+":")]),_c('worksheet-card',{attrs:{"worksheet":_vm.worksheet}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
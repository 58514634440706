import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column text-center"},[_c(VList,{staticClass:"mx-auto",attrs:{"width":"300px","dense":""}},[_c('span',{staticClass:"text-overline"},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.worksheet.worksheetSummary")))]),_c(VListItem,{staticClass:"mx-8"},[_c(VListItemContent,[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.base.grossWeight")))]),_c(VListItemContent,{staticClass:"align-end"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.sum.grossWeight))+" "+_vm._s(_vm.weightUnit)+" ")])],1),_c(VListItem,{staticClass:"mx-8"},[_c(VListItemContent,[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.base.netWeight")))]),_c(VListItemContent,{staticClass:"align-end"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.sum.netWeight))+" "+_vm._s(_vm.weightUnit)+" ")])],1),(_vm.worksheetTypeFields.hasTargetWeight)?_c(VListItem,{staticClass:"mx-8"},[_c(VListItemContent,[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.base.targetWeight")))]),_c(VListItemContent,{staticClass:"align-end"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.worksheet.targetWeight))+" "+_vm._s(_vm.weightUnit)+" ")])],1):_vm._e()],1),_c('hr'),_c(VSubheader,{staticClass:"mx-auto"},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.worksheet.netWeightBeforeSubmit"))+":")]),_c(VTextField,{staticClass:"mx-auto mt-8",staticStyle:{"max-width":"250px","font-size":"26px"},attrs:{"label":_vm.$vuetify.lang.t('$vuetify.base.netAdjustmentWeight'),"outlined":"","type":"number","inputmode":"decimal","min":"0","disabled":_vm.submitted},on:{"change":function($event){_vm.calculateNetAdjustPercentage();
        _vm.$emit('update:worksheet', _vm.worksheet);}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" "+_vm._s(_vm.weightUnit)+" ")]},proxy:true}]),model:{value:(_vm.worksheet.netAdjustmentWeight),callback:function ($$v) {_vm.$set(_vm.worksheet, "netAdjustmentWeight", _vm._n($$v))},expression:"worksheet.netAdjustmentWeight"}}),_c('div',{staticClass:"mb-5 text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.base.netAdjustment"))+": "+_vm._s(_vm._f("formatNumber")(_vm.netAdjustPercentage))+"% ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
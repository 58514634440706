import { render, staticRenderFns } from "./InventoryLabResultUpload.vue?vue&type=template&id=123c75ae&scoped=true"
import script from "./InventoryLabResultUpload.vue?vue&type=script&lang=js"
export * from "./InventoryLabResultUpload.vue?vue&type=script&lang=js"
import style0 from "./InventoryLabResultUpload.vue?vue&type=style&index=0&id=123c75ae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "123c75ae",
  null
  
)

export default component.exports
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsDivider } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view"},[_c(VBreadcrumbs,[_c(VBreadcrumbsItem,{attrs:{"exact":true,"to":{ name: 'home', params: {} }}},[_vm._v("Home")]),_c(VBreadcrumbsDivider,[_vm._v("/")]),_c(VBreadcrumbsItem,{attrs:{"exact":true,"to":{ name: 'processWorksheet', params: {} }}},[_vm._v(" Process Worksheet ")]),_c(VBreadcrumbsDivider,[_vm._v("/")]),_c(VBreadcrumbsItem,{attrs:{"to":{ name: 'processWorksheetView', params: {} }}},[_vm._v(" View Process Worksheet ")])],1),_c('div',[(_vm.id)?_c('worksheet-card',{attrs:{"worksheet":_vm.id,"showEditButtons":true}}):_c(VSheet,{staticClass:"pa-4 text-center"},[_vm._v("Not found")])],1),_c('div',{staticClass:"d-flex justify-space-between ma-5"},[_c(VBtn,{on:{"click":_vm.goBack}},[_vm._v("Back")]),_c('span')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
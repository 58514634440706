import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c(VSelect,{attrs:{"label":"Supervisor","placeholder":"Choose a supervisor","items":_vm.supervisors,"item-text":"name","item-value":"id","outlined":"","return-object":""},on:{"change":function($event){return _vm.fetchReportsBySupervisor(_vm.supervisor)}},model:{value:(_vm.supervisor),callback:function ($$v) {_vm.supervisor=$$v},expression:"supervisor"}}),(_vm.supervisor)?_c('div',[_vm._l((_vm.parameters),function(p,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('legend',[_vm._v("Report "+_vm._s(index + 1))]),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.removeReport(index)}}},[_c(VIcon,[_vm._v(" mdi-delete")])],1)],1),_c('div',[_c(VTextField,{attrs:{"outlined":"","label":"Report Name"},model:{value:(p.name),callback:function ($$v) {_vm.$set(p, "name", $$v)},expression:"p.name"}}),_c(VSelect,{staticClass:"mt-2",attrs:{"items":_vm.worksheetTypeDefinitions,"item-text":"worksheetType","item-value":"id","label":"Filter by Worksheet Type","placeholder":"All Worksheet Types","clearable":"","outlined":"","return-object":""},model:{value:(p.type),callback:function ($$v) {_vm.$set(p, "type", $$v)},expression:"p.type"}})],1),(p.type && p.type.hasProcessType && _vm.processTypes)?_c('div',[_c(VSelect,{attrs:{"clearable":"","outlined":"","label":"Manufactoring Process Types","items":_vm.processTypes,"item-text":"name","item-value":"id"},model:{value:(p.processTypeId),callback:function ($$v) {_vm.$set(p, "processTypeId", $$v)},expression:"p.processTypeId"}})],1):_vm._e()])}),_c(VBtn,{on:{"click":_vm.addReport}},[_vm._v(" Add Report ")]),_c(VDivider),_c('div',{staticClass:"text-center"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save ")])],1)],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
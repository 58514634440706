<template>
    <div class="d-flex flex-column">
      <v-form ref="form" v-model="valid" @submit.prevent="submit">
        <v-select
          v-model="selectedProduct"
          :items="availableProducts"
          item-text="name"
          item-value="id"
          :label="$vuetify.lang.t('$vuetify.worksheet.selectProduct')"
          :loading="loading"
          :disabled="loading || submitted"
          :rules="[v => !!v || $vuetify.lang.t('$vuetify.validation.required')]"
          @change="onProductChange"
        ></v-select>
  
        <v-btn
          :disabled="!valid || loading || submitted"
          color="primary"
          class="mt-4"
          @click="submit"
        >
          {{ $vuetify.lang.t('$vuetify.base.update') }}
        </v-btn>
      </v-form>
  
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
        class="ma-4"
      ></v-progress-circular>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  
  export default {
    name: "WorksheetProductEditor",
    props: {
      worksheet: {
        type: Object,
        required: true,
      },
      productInfo: {
        type: Object,
        required: false,
      },
      worksheetTypeFields: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        availableProducts: [],
        selectedProduct: this.worksheet.productId || null,
        loading: false,
        valid: false,
        submitted: ["SUBMITTED", "USED"].includes(this.worksheet.status),
      };
    },
    computed: {
      ...mapGetters("processWorksheet", ["uniqueProducts"]),
    },
    created() {
      this.fetchAvailableProducts();
    },
    methods: {
      ...mapActions("processWorksheet", ["fetchUniqueProducts", "updateProcessWorksheet"]),
      ...mapActions("messages", ["addErrorMessage", "addSuccessMessage"]),
  
      async fetchAvailableProducts() {
        this.loading = true;
        try {
          const response = await this.fetchUniqueProducts({
            processTypeId: this.worksheet.processTypeId,
            worksheetTypeId: this.worksheet.worksheetTypeId,
          });
          this.availableProducts = response;
        } catch (error) {
          this.addErrorMessage(this.$vuetify.lang.t("$vuetify.messages.fetchProductsError"));
          console.error("Failed to load available products:", error);
        } finally {
          this.loading = false;
        }
      },
  
      onProductChange(value) {
        // Optional: Add any additional logic when the product changes
        console.log("Selected Product ID:", value);
      },
  
      async submit() {
        if (this.$refs.form.validate()) {
          this.loading = true;
          try {
            const updatedWorksheet = { ...this.worksheet, productId: this.selectedProduct };
            const response = await this.updateProcessWorksheet(updatedWorksheet);
            this.addSuccessMessage(this.$vuetify.lang.t("$vuetify.messages.updateSuccess"));
            this.$emit("updated", response);
          } catch (error) {
            this.addErrorMessage(this.$vuetify.lang.t("$vuetify.messages.updateError"));
            console.error("Failed to update worksheet product:", error);
          } finally {
            this.loading = false;
          }
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any component-specific styles here */
  </style>
  
<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-card class="pa-5">
          <v-card-title>
            <span class="headline">
              {{ $vuetify.lang.t("$vuetify.processConversionReport.title") }}
            </span>
          </v-card-title>
          <v-card-text>
            <!-- Parameters Component -->
            <conversion-report-parameters
              :processTypes="processTypes"
              :linkedProcessTypes="linkedProcessTypes"
              :inputProducts="inputProducts"
              :outputProducts="outputProducts"
              :isInputProductsLoading="isInputProductsLoading"
              :isOutputProductsLoading="isOutputProductsLoading"
              @parametersSelected="handleParametersSelected"
            ></conversion-report-parameters>

            <v-divider class="my-5"></v-divider>

            <!-- Loading & Error States -->
            <div v-if="loadingReport">
              <v-progress-linear
                indeterminate
                color="primary"
              ></v-progress-linear>
            </div>
            <div v-else-if="!reportData">
              <v-alert type="info">
                {{
                  $vuetify.lang.t("$vuetify.processConversionReport.noReport")
                }}
              </v-alert>
            </div>
            <!-- Summaries & Inventories -->
            <div v-else>
              <!-- Summary Card -->
              <v-row>
                <v-col cols="12" md="4">
                  <v-card>
                    <v-card-title>
                      {{
                        $vuetify.lang.t(
                          "$vuetify.processConversionReport.totalInputWeight"
                        )
                      }}
                    </v-card-title>
                    <v-card-text>
                      {{ formatWeight(reportData.totalInputWeight) }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card>
                    <v-card-title>
                      {{
                        $vuetify.lang.t(
                          "$vuetify.processConversionReport.totalOutputWeight"
                        )
                      }}
                    </v-card-title>
                    <v-card-text>
                      {{ formatWeight(reportData.totalOutputWeight) }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card>
                    <v-card-title>
                      {{
                        $vuetify.lang.t(
                          "$vuetify.processConversionReport.conversionRate"
                        )
                      }}
                    </v-card-title>
                    <v-card-text>
                      {{ computeConversionRate(reportData) }}%
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-divider class="my-5"></v-divider>

              <!-- Input Inventories Table -->
              <div class="mb-3">
                <h3>
                  {{
                    $vuetify.lang.t(
                      "$vuetify.processConversionReport.inputInventoriesTitle"
                    )
                  }}
                </h3>
                <v-data-table
                  :headers="inventoryHeaders"
                  :items="reportData.inputInventories"
                  :items-per-page="5"
                  class="elevation-1"
                  dense
                >
                  <template v-slot:item.weight="{ item }">
                    {{ formatWeight(item.weight) }}
                  </template>
                </v-data-table>
              </div>

              <v-divider class="my-5"></v-divider>

              <!-- Output Inventories Table -->
              <div class="mb-3">
                <h3>
                  {{
                    $vuetify.lang.t(
                      "$vuetify.processConversionReport.outputInventoriesTitle"
                    )
                  }}
                </h3>
                <v-data-table
                  :headers="inventoryHeaders"
                  :items="reportData.outputInventories"
                  :items-per-page="5"
                  class="elevation-1"
                  dense
                >
                  <template v-slot:item.weight="{ item }">
                    {{ formatWeight(item.weight) }}
                  </template>
                </v-data-table>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ConversionReportParameters from "@/components/process/ConversionReportParameters.vue";

export default {
  name: "ProcessConversionReport",
  components: {
    ConversionReportParameters,
  },
  data() {
    return {
      loadingReport: false,
      reportData: null, // This is now SummarizeWithInventoriesSummary
      reportParams: {
        startDate: this.getFirstDayOfCurrentMonth(),
        endDate: this.getLastDayOfCurrentMonth(),
        inputProductIds: [],
        outputProductIds: [],
        processIds: [],
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "currentUser", "userRoles"]),
    ...mapGetters("processes", [
      "processTypes",
      "linkedProcessTypes",
      "inputProducts",
      "outputProducts",
    ]),

    inventoryHeaders() {
      return [
        {
          text: this.$vuetify.lang.t(
            "$vuetify.processConversionReport.inventoryId"
          ),
          value: "inventoryId",
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.processConversionReport.inventoryName"
          ),
          value: "inventoryName",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.processConversionReport.weight"),
          value: "weight",
        },
      ];
    },
  },
  methods: {
    ...mapActions("processes", [
      "loadProcessTypes",
      "loadLinkedProcessTypes",
      "loadInputProducts",
      "loadOutputProducts",
    ]),
    async handleParametersSelected(payload) {
      const { reportParams } = payload;
      this.reportParams = reportParams;
      await this.fetchReport();
    },
    async fetchReport() {
      this.loadingReport = true;
      this.reportData = null;
      try {
        const payload = {
          processIds: this.reportParams.processIds,
          inputProductIds: this.reportParams.inputProductIds,
          outputProductIds: this.reportParams.outputProductIds,
          includeSubgraph: true
        };

        // POST to the new summary endpoint
        const response = await this.$axios.post(
          "/api/conversion-reports/summary",
          payload
        );
        this.reportData = response.data.summary;
        console.log("Received summary:", this.reportData);
      } catch (error) {
        console.error("Error fetching summary:", error);
        this.showAlert(
          "error",
          this.$vuetify.lang.t("$vuetify.processConversionReport.fetchError")
        );
      } finally {
        this.loadingReport = false;
      }
    },
    formatWeight(weight) {
      return weight !== null && weight !== undefined
        ? weight.toFixed(2)
        : "n/a";
    },
    computeConversionRate(reportData) {
      if (!reportData || reportData.totalInputWeight === 0) return 0;
      const rate = reportData.totalOutputWeight / reportData.totalInputWeight;
      return (rate * 100).toFixed(2);
    },
    showAlert(type, message) {
      this.$root.$emit("show-snackbar", { type, message });
    },
    getFirstDayOfCurrentMonth() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), 1)
        .toISOString()
        .substr(0, 10);
    },
    getLastDayOfCurrentMonth() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth() + 1, 0)
        .toISOString()
        .substr(0, 10);
    },
  },
};
</script>


<style scoped>
.headline {
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}
</style>

import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form",attrs:{"id":"companyVehicleForm"}},[(_vm.loaded)?_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"label":"License Plate","required":true},model:{value:(_vm.companyVehicle.vehicleLicensePlate),callback:function ($$v) {_vm.$set(_vm.companyVehicle, "vehicleLicensePlate", $$v)},expression:"companyVehicle.vehicleLicensePlate"}}),_c(VSelect,{attrs:{"items":_vm.companyVehicle.relations.vehicleType,"label":"Type","chips":"","deletable-chips":""},model:{value:(_vm.companyVehicle.vehicleType),callback:function ($$v) {_vm.$set(_vm.companyVehicle, "vehicleType", $$v)},expression:"companyVehicle.vehicleType"}}),_c(VTextField,{attrs:{"label":"Notes","required":false},model:{value:(_vm.companyVehicle.vehicleNotes),callback:function ($$v) {_vm.$set(_vm.companyVehicle, "vehicleNotes", $$v)},expression:"companyVehicle.vehicleNotes"}}),_c(VAutocomplete,{attrs:{"items":_vm.companyVehicle.relations.company,"label":"Company","multiple":false,"item-text":"name","item-value":"id","chips":false,"deletable-chips":""},model:{value:(_vm.companyVehicle.companyId),callback:function ($$v) {_vm.$set(_vm.companyVehicle, "companyId", $$v)},expression:"companyVehicle.companyId"}}),_c('div',{staticClass:"mt-4 d-flex justify-space-between"},[_c(VBtn,{on:{"click":_vm.goBack}},[_vm._v(" Back ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.id ? "Update" : "Save")+" ")]),_c('span')],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
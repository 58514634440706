import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.data && _vm.data.children && _vm.data.children.length > 0)?_c('svg',{staticClass:"treemap",attrs:{"width":_vm.width,"height":_vm.height}},[_c('g',{ref:"content",attrs:{"transform":_vm.transform}},[_vm._t("default",null,{"width":_vm.contentWidth,"height":_vm.contentHeight})],2)]):_vm._e(),_c(VBottomSheet,{attrs:{"scrollable":""},model:{value:(_vm.showOthers),callback:function ($$v) {_vm.showOthers=$$v},expression:"showOthers"}},[_c(VCard,[_c(VCardTitle,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.showOthers = false}}},[_vm._v(" Close ")])],1),_c(VCardText,[(_vm.otherNodes)?_c(VList,[_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.otherNodes),function(node){return _c(VListItem,{key:node.id,on:{"click":function($event){return _vm.clickedOtherNode(node)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(node.name))]),(node.reservedQuantity)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.inventory.reserved'))+": "+_vm._s(_vm._f("formatInteger")(node.reservedQuantity))+" "+_vm._s(node.measureWeight)+" ")]):_vm._e()],1),_c(VListItemActionText,[_vm._v(" "+_vm._s(_vm._f("formatInteger")(node.value))+" "+_vm._s(node.measureWeight)+" ")])],1)}),1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VApp } from 'vuetify/lib/components/VApp';
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"inspire"}},[(_vm.deferredPrompt)?_c(VBanner,{staticClass:"text-left mt-14",attrs:{"color":"primary","dark":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"text":""},on:{"click":_vm.dismiss}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.home.dismiss")))]),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.install}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.home.install")))])]},proxy:true}],null,false,2225906420)},[_c('div',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.home.installAppPrompt", _vm.title))+" ")])]):_vm._e(),(_vm.showMenu)?_c('Sidebar',{staticClass:"d-print-none"}):_vm._e(),(_vm.showMenu)?_c('Header',{staticClass:"d-print-none"}):_vm._e(),_c(VMain,{staticClass:"print-mode"},[_c('message-component'),_c('router-view',{key:_vm.$route.fullPath}),(_vm.refreshSnack)?_c(VSnackbar,{staticClass:"mb-7",attrs:{"timeout":-1,"multi-line":true,"value":true,"bottom":"","color":"success","center":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({staticClass:"mx-md-2",attrs:{"text":""},on:{"click":function($event){return _vm.refreshPage()}}},'v-btn',attrs,false),[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.base.refresh")))])]}}],null,false,228424949)},[_c('div',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.base.refreshMsg")))])]):_vm._e()],1),_c('div',{staticClass:"d-print-none text-caption text-right font-weight-light"},[_vm._v(" Version "+_vm._s(_vm.appVersion)+" - "+_vm._s(_vm.appRevisionDate)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
// store/modules/processes.js

import TreeModel from "tree-model";
import Vue from 'vue'; // Ensure Vue is imported for reactivity

const state = {
  processTypes: null,
  loading: false,
  linkedProcessTypes: {}, // Stores linked process types keyed by processTypeId
  inputProducts: [],      // Stores unique input products
  outputProducts: [],     // Stores unique output products
  fetchedProcesses: [],   // Stores fetched processes based on filters
};

const mutations = {
  setProcessTypes(state, list) {
    state.processTypes = list;
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setLinkedProcessTypes(state, { processTypeId, linkedTypes }) {
    Vue.set(state.linkedProcessTypes, processTypeId, linkedTypes);
  },
  setInputProducts(state, products) {
    state.inputProducts = products;
  },
  setOutputProducts(state, products) {
    state.outputProducts = products;
  },
  setFetchedProcesses(state, processes) { // New Mutation
    state.fetchedProcesses = processes;
  },
};

const actions = {
  /**
   * Fetch all process types.
   */
  async loadProcessTypes({ commit, dispatch }) {
    commit('setLoading', true);
    try {
      const response = await this._vm.$axios.get("/category/childs", {
        params: {
          code: "PROCESSES"
        }
      });
      const data = response.data;
      if (data.code !== 200) {
        const message = data.message;
        dispatch("messages/addMessage", {
          message,
          messageClass: "danger"
        }, { root: true });
        return;
      } else {
        const rootProcesses = data.data;
        const root = { name: 'root', id: 0, children: rootProcesses };

        const model = new TreeModel();
        const rootCategory = model.parse(root);
        // Assign level and other properties
        rootCategory.walk((node) => { 
          const path = node.getPath();
          const hasChildren = node.hasChildren();
          node.model.level = path.length - 1; // Exclude root
          node.model.path = path.map(p => p.model.id);
          node.model.hasChildren = hasChildren;
          if (hasChildren) {
            const leafNodes = node.all(function (n) {
              return !n.hasChildren();
            });
            if (leafNodes && leafNodes.length > 0)
              node.model.leafNodeIds = leafNodes.map(l => l.model.id);
          }
          node.model.state = 'closed';
        });

        const leafNodes = actions.getAllProcessTypes(rootCategory);
        let allProcessTypes = leafNodes.map(node => node.model);
        allProcessTypes.sort((a, b) => (a.name > b.name) ? 1 : -1);
        commit("setProcessTypes", allProcessTypes);

        return rootCategory;
      }
    } catch (error) {
      console.error('Error loading process types:', error);
      dispatch("messages/addMessage", {
        message: "Failed to load process types.",
        messageClass: "danger"
      }, { root: true });
    } finally {
      commit('setLoading', false);
    }
  },
  
  getAllProcessTypes(rootNode) {
    if (rootNode) {
      const leafNodes = rootNode.all(function (node) {
        return node.model.children == null || node.model.children.length < 1;
      });
      return leafNodes;
    } else {
      return [];
    }
  },
  
  /**
   * Fetch linked process types based on the selected processTypeId and date range.
   */
  async loadLinkedProcessTypes({ commit, dispatch }, { processTypeId, startDate, endDate }) {
    try {
      const response = await this._vm.$axios.get("/api/conversion-reports/linked-process-types", { // Ensure this endpoint exists
        params: {
          processTypeId: processTypeId,
          startDate,
          endDate
        }
      });
  
      const data = response.data;
  
      if (response.status !== 200) {
        const message = data.message;
        dispatch("messages/addMessage", {
          message,
          messageClass: "danger"
        }, { root: true });
        return [];
      } else {
        const linkedTypes = data; // Assuming data is an array of linked process types
        commit('setLinkedProcessTypes', { processTypeId, linkedTypes });
        return linkedTypes;
      }
    } catch (error) {
      console.error('Error loading linked process types:', error);
      dispatch("messages/addMessage", {
        message: "Failed to load linked process types.",
        messageClass: "danger"
      }, { root: true });
      return [];
    }
  },
  
  /**
   * Fetch unique input products based on parameters.
   */
  async loadInputProducts({ commit, dispatch }, { startDate, endDate, processTypeIds }) {
    try {
      const response = await this._vm.$axios.get('/api/conversion-reports/input-products', {
        params: {
          startDate: startDate,
          endDate: endDate,
          processTypeIds: processTypeIds ? processTypeIds.join(',') : null
        }
      });
      
      if (response.status === 200) {
        commit('setInputProducts', response.data);
        return response.data;
      } else {
        dispatch("messages/addMessage", {
          message: "Failed to load input products.",
          messageClass: "danger"
        }, { root: true });
        return [];
      }
    } catch (error) {
      console.error('Error loading input products:', error);
      dispatch("messages/addMessage", {
        message: "Failed to load input products.",
        messageClass: "danger"
      }, { root: true });
      return [];
    }
  },
  
  /**
   * Fetch unique output products based on parameters.
   */
  async loadOutputProducts({ commit, dispatch }, { startDate, endDate, processTypeIds }) {
    try {
      const response = await this._vm.$axios.get('/api/conversion-reports/output-products', {
        params: {
          startDate: startDate,
          endDate: endDate,
          processTypeIds: processTypeIds ? processTypeIds.join(',') : null
        }
      });
      
      if (response.status === 200) {
        commit('setOutputProducts', response.data);
        return response.data;
      } else {
        dispatch("messages/addMessage", {
          message: "Failed to load output products.",
          messageClass: "danger"
        }, { root: true });
        return [];
      }
    } catch (error) {
      console.error('Error loading output products:', error);
      dispatch("messages/addMessage", {
        message: "Failed to load output products.",
        messageClass: "danger"
      }, { root: true });
      return [];
    }
  },

  /**
   * Fetches processes based on the provided filters using GET request with query parameters.
   * 
   * @param {Object} context - Vuex context object.
   * @param {Object} payload - Filter parameters.
   * @param {String} payload.startDate - Start date in ISO format (YYYY-MM-DD).
   * @param {String} payload.endDate - End date in ISO format (YYYY-MM-DD).
   * @param {Array} payload.processTypeIds - Array of selected process type IDs.
   * @param {Array} payload.inputProductIds - Array of selected input product IDs.
   * @param {Array} payload.outputProductIds - Array of selected output product IDs.
   * 
   * @returns {Array} - Array of fetched processes.
   */
  async loadProcesses({ commit, dispatch }, { startDate, endDate, processTypeIds, inputProductIds, outputProductIds }) {
    commit('setLoading', true);
    try {
      const response = await this._vm.$axios.get('/api/conversion-reports/processes', {
        params: {
          startDate,
          endDate,
          processTypeIds: processTypeIds ? processTypeIds.join(',') : null,
          inputProductIds: inputProductIds ? inputProductIds.join(',') : null,
          outputProductIds: outputProductIds ? outputProductIds.join(',') : null,
        }
      });

      // Debugging: Inspect the response structure
      console.log('Processes response:', response);

      // Assuming the API returns data directly as a list: [...]
      const processes = Array.isArray(response.data) ? response.data : response.data.data;

      // Debugging: Inspect the processes array
      console.log('Processes array:', processes);

      // Assign the response directly to availableProcesses for the table
      // Ensure that all required fields are mapped correctly
      const formattedProcesses = processes.map(process => ({
        id: process.id,
        processCode: process.processCode,
        processStartTime: process.processStartTime,
        processEndTime: process.processEndTime,
        processTypeName: process.processTypeName,
        status: process.status,
        matchesInput: process.matchesInput,
        matchesOutput: process.matchesOutput,
      }));

      // Debugging: Inspect the mapped availableProcesses
      console.log('Mapped availableProcesses:', formattedProcesses);

      commit('setFetchedProcesses', formattedProcesses);
      return formattedProcesses;
    } catch (error) {
      console.error('Error loading processes:', error);
      dispatch("messages/addMessage", {
        message: "Failed to load processes.",
        messageClass: "danger"
      }, { root: true });
      return [];
    } finally {
      commit('setLoading', false);
    }
  },
};

const getters = {
  processTypes: state => state.processTypes,
  isAppLoading: state => state.loading,
  /**
   * Returns linked process types for a given processTypeId.
   * @param {Object} state - Vuex state.
   * @returns {Function} - Function that takes processTypeId and returns linked process types.
   */
  linkedProcessTypes: (state) => (processTypeId) => {
    return state.linkedProcessTypes[processTypeId] || [];
  },
  isLinkedProcessTypesLoading: (state) => (processTypeId) => {
    // Implement loading state for linked process types if needed
    return false; // Placeholder: Update if you track loading states
  },
  inputProducts: (state) => state.inputProducts,
  outputProducts: (state) => state.outputProducts,
  fetchedProcesses: (state) => state.fetchedProcesses, // New Getter
};

export default {
  namespaced: true, // Enable namespacing for clarity
  state,
  mutations,
  actions,
  getters
};

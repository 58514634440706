import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,_vm._g(_vm._b({staticStyle:{"z-index":"1000"},attrs:{"width":"80%","fullscreen":_vm.fullscreen},on:{"input":_vm.close},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c(VCard,{staticClass:"ma-0 pa-0 ma-md-5 pa-md-5"},[_c(VCardTitle,{staticClass:"d-flex mb-5"},[(_vm.hasTitleSlot)?_c('div',{staticClass:"headerClass"},[(_vm.hasTitleSlot)?_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]}):_vm._e()],2):_vm._e(),_c(VSpacer),_c('div',[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.showDialog = null}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),(_vm.subtitle || _vm.hasSubtitleSlot)?_c(VCardSubtitle,[_vm._t("subtitle",function(){return [_vm._v(" "+_vm._s(_vm.subtitle)+" ")]})],2):_vm._e(),_c(VCardText,{staticClass:"mx-0 py-5 px-0 ma-md-5 pa-md-5"},[_vm._t("default",function(){return [_c(VSheet,{staticClass:"pa-5"},[_vm._v(" No result found ")])]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
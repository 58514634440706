<template>
  <div v-if="!loading">
    <worksheet-header
      :worksheet.sync="localWorksheet"
      :worksheetTypeFields="worksheetTypeFields"
      :sum.sync="sum"
      :productInfo="productInfo"
      @jobUpdated="jobChanged"
    />

    <hr />

    <div
      class="text-center"
      v-if="localWorksheet.status === 'SUBMITTED' || localWorksheet.status === 'USED'"
    >
      <v-alert outlined color="success" class="mb-7 text-center">
        <span v-if="localWorksheet.status === 'SUBMITTED'" class="my-auto">
          {{ $vuetify.lang.t("$vuetify.worksheet.submittedOn") }}
          {{ localWorksheet.submittedTime | formatDateTime }}
        </span>
        <span v-else>Worksheet Used</span>
        <v-btn
          @click="toggleStatus"
          class="ma-4"
          outlined
          v-if="isManagement && localWorksheet.status === 'SUBMITTED'"
        >
          Change Status
        </v-btn>
      </v-alert>
    </div>

    <v-fade-transition hide-on-leave="true">
      <worksheet-settings
        v-if="stage === 0 && hasStage0"
        @onComplete="completePrerequisite"
        @update:worksheet="handleWorksheetUpdate"
        :worksheet.sync="localWorksheet"
        :worksheetTypeFields="worksheetTypeFields"
        :productInfo="productInfo"
      />
    </v-fade-transition>

    <v-fade-transition hide-on-leave="true">
      <worksheet-entry
        v-if="stage === 1 && localWorksheet.status === 'PENDING'"
        :worksheet="localWorksheet"
        :worksheetTypeFields="worksheetTypeFields"
        :sum="sum"
        :currentEntry="currentEntry"
        :productInfo="productInfo"
        :key="currentEntry.id || 'new-entry'"
        @add-entry="handleAddEntry"
        @update-entry="handleUpdateEntry"
        @update:currentEntry="handleUpdateCurrentEntry"
      />
    </v-fade-transition>

    <v-divider class="mt-4" />

    <div class="d-flex justify-space-between">
      <div v-if="localWorksheet.memo">
        <v-subheader>
          {{ $vuetify.lang.t("$vuetify.base.memo") }}:
          <div class="ml-2">
            {{ localWorksheet.memo }}
          </div>
        </v-subheader>
      </div>
    </div>

    <v-bottom-navigation grow app>
      <v-btn
        v-if="
          (stage === 1 && hasStage0) ||
          (localWorksheet.status === 'SUBMITTED' && isManagement)
        "
        @click="stage = 0"
      >
        <span>{{ $vuetify.lang.t("$vuetify.base.settings") }}</span>
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
      <v-btn v-if="!hasEntries" @click="deleteWorksheet">
        <span>{{ $vuetify.lang.t("$vuetify.base.delete") }}</span>
        <v-icon color="red">mdi-trash-can</v-icon>
      </v-btn>
      <v-btn @click="entriesDialog = true" v-if="hasEntries">
        <span>
          {{ localWorksheet.entries.length }}
          {{ $vuetify.lang.t("$vuetify.base.entries") }}
        </span>
        <v-icon>mdi-format-list-numbered</v-icon>
      </v-btn>
      <v-btn
        @click="
          timestamp = Date.now();
          notesDialog = true;
        "
        v-if="worksheetTypeFields.hasNotes"
      >
        <span>{{ $vuetify.lang.t("$vuetify.base.notes") }}</span>
        <v-icon>mdi-note-edit-outline</v-icon>
      </v-btn>
      <v-btn v-if="stage === 0 && !submitted" @click="completePrerequisite">
        <span>{{ $vuetify.lang.t("$vuetify.base.entries") }}</span>
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
      <v-btn
        :disabled="loading"
        v-if="hasEntries && !submitted"
        @click="submitWorksheet"
      >
        <span>{{ $vuetify.lang.t("$vuetify.base.submit") }}</span>
        <v-icon>mdi-arrow-up-circle-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-dialog
      style="z-index: 1000"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="entriesDialog"
    >
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-btn icon @click="entriesDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <worksheet-items
            :worksheet="localWorksheet"
            :sum="sum"
            :worksheetTypeFields="worksheetTypeFields"
            :productInfo="productInfo"
            :editable="true"
            @editEntry="editEntry"
            @deletedEntry="handleDeleteEntry"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      style="z-index: 1000"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="preSubmitDialog"
    >
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-btn icon @click="preSubmitDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <worksheet-entry-pre-submit
            :worksheet.sync="localWorksheet"
            :sum="sum"
            :weightUnit="weightUnit"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn class="ma-8" @click="preSubmitDialog = false">
            {{ $vuetify.lang.t("$vuetify.base.cancel") }}
          </v-btn>
          <v-btn class="ma-8" color="primary" @click="submitWorksheet">
            {{ $vuetify.lang.t("$vuetify.base.submit") }}
            {{ $vuetify.lang.t("$vuetify.worksheet.worksheet") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <worksheet-notes
      v-model="notesDialog"
      :worksheet="localWorksheet"
      :key="timestamp"
    />
    <confirmation-dialog ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getProcessWorksheet } from "@/services/processWorksheet";
import { sumWorksheetWeight } from "@/services/worksheetServices";

const WorksheetSettings = () =>
  import("@/components/processWorksheet/WorksheetSettings.vue");
const WorksheetEntryPreSubmit = () =>
  import("@/components/processWorksheet/WorksheetEntryPreSubmit.vue");
const WorksheetNotes = () =>
  import("@/components/processWorksheet/WorksheetNotes.vue");
const WorksheetHeader = () =>
  import("@/components/processWorksheet/WorksheetHeader.vue");
const WorksheetEntry = () =>
  import("@/components/processWorksheet/WorksheetEntry.vue");
const WorksheetItems = () =>
  import("@/components/processWorksheet/WorksheetItems.vue");

export default {
  props: {
    worksheet: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      validGrossWeight: false,
      showEntryNotes: false,
      addTime: Date.now(),
      currentEntry: null,
      entriesDialog: false,
      preSubmitDialog: false,
      notesDialog: false,
      sum: { grossWeight: 0, netWeight: 0 },
      imageData: null,
      productInfo: null,
      timestamp: Date.now(),
      stage: 0, // 0 - supplier/weight-settings, 1 - enter weight;  2 - pre-submission
      localWorksheet: { ...this.worksheet }, // Local copy of worksheet
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser", "userRoles"]),
    ...mapGetters("worksheetTypeDefinition", ["worksheetTypeDefinitions"]),
    isManagement() {
      const permissibleRoles = ["Administrator", "Manager", "Factory"];
      if (
        this.userRoles &&
        this.userRoles.some((r) => permissibleRoles.includes(r))
      ) {
        return true;
      } else {
        return false;
      }
    },
    weightUnit() {
      if (this.productInfo) {
        return this.productInfo.measureType === "Unit"
          ? this.productInfo.measureUnit
          : this.productInfo.measureWeight;
      } else {
        return null;
      }
    },
    entryIconsLayout() {
      let hasFiles = false;
      if (this.currentEntry && this.currentEntry.files) {
        hasFiles = this.currentEntry.files.length > 0;
      }

      let hasNotes = false;
      if (this.showEntryNotes || (this.currentEntry && this.currentEntry.notes)) {
        hasNotes = true;
      }

      const useColumn = hasFiles || hasNotes;
      return useColumn ? "flex-column" : "flex-row";
    },
    netWeight() {
      let grossWeight = 0;
      if (this.currentEntry && this.currentEntry.grossWeight) {
        grossWeight = this.currentEntry.grossWeight;
      }
      return grossWeight - (this.localWorksheet.deductibleWeight || 0);
    },
    hasEntries() {
      console.log("has entry changed...");
      return (
        this.localWorksheet.entries &&
        Array.isArray(this.localWorksheet.entries) &&
        this.localWorksheet.entries.length > 0
      );
    },
    hasStage0() {
      if (this.localWorksheet.status === "SUBMITTED") {
        return true;
      }

      if (this.worksheetTypeFields) {
        return (
          this.worksheetTypeFields.hasWeightSettings ||
          this.worksheetTypeFields.hasSupplier ||
          this.worksheetTypeFields.hasCustomer ||
          this.worksheetTypeFields.hasDocumentNumber
        );
      } else {
        return false;
      }
    },
    hasPreSubmit() {
      const hasNetAdjWeight =
        this.worksheetTypeFields && this.worksheetTypeFields.hasNetAdjustmentWeight;
      return hasNetAdjWeight;
    },
    worksheetTypeFields() {
      if (
        this.worksheetTypeDefinitions &&
        this.worksheetTypeDefinitions.length > 0 &&
        this.localWorksheet.worksheetTypeId
      ) {
        return this.worksheetTypeDefinitions.find(
          (t) => t.id === this.localWorksheet.worksheetTypeId
        );
      } else {
        return {};
      }
    },

    submitted() {
      if (
        this.localWorksheet &&
        ["SUBMITTED", "USED"].includes(this.localWorksheet.status)
      ) {
        return true;
      } else {
        return false;
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
  components: {
    WorksheetItems,
    WorksheetNotes,
    WorksheetHeader,
    WorksheetEntry,
    WorksheetEntryPreSubmit,
    WorksheetSettings,
  },
  watch: {
    worksheet: {
      handler(newVal) {
        this.localWorksheet = { ...newVal };
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    try {
      const response = await getProcessWorksheet(this.localWorksheet.id);
      this.localWorksheet = response.data;

      // Ensure 'entries' is initialized as an array
      if (!Array.isArray(this.localWorksheet.entries)) {
        this.$set(this.localWorksheet, "entries", []);
      }

      await this.fetchWorksheetTypeDefinitions();
      await this.loadProductInfo();

      if (this.hasEntries || !this.hasStage0) {
        this.currentEntry = {};
        this.stage = 1;
      }

      if (
        this.localWorksheet &&
        this.localWorksheet.status === "SUBMITTED" &&
        this.worksheetTypeFields.hasNotes
      ) {
        this.stage = 2;
      }

      this.sumWeight();
    } catch (error) {
      this.addErrorMessage("Error fetching worksheet data.");
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("processWorksheet", [
      "submitProcessWorksheet",
      "deleteProcessWorksheet",
      "updateProcessWorksheet",
    ]),
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("worksheetTypeDefinition", ["fetchWorksheetTypeDefinitions"]),
    ...mapActions("categoryStore", ["fetchCategory"]),

    completePrerequisite() {
      this.sumWeight();
      this.currentEntry = {};
      this.stage = 1;
    },
    async submitWorksheet() {
      let confirm = false;
      // Check if worksheet has pre-submit module
      if (this.hasPreSubmit && !this.preSubmitDialog) {
        this.preSubmitDialog = true;
      } else {
        confirm = await this.$refs.confirm.open(
          this.$vuetify.lang.t("$vuetify.base.confirmSubmission"),
          this.$vuetify.lang.t("$vuetify.worksheet.submitConfirmMsg")
        );
      }

      if (confirm) {
        this.submitProcessWorksheet(this.localWorksheet)
          .then((submittedWorksheet) => {
            this.addSuccessMessage(
              this.$vuetify.lang.t("$vuetify.worksheet.submitSuccessMsg")
            );

            this.localWorksheet = { ...submittedWorksheet };
            this.$emit("update:worksheet", this.localWorksheet);
            this.$emit("submitted", this.localWorksheet);
            if (this.preSubmitDialog) {
              this.preSubmitDialog = false;
            }
          })
          .catch(() => {
            this.addErrorMessage("Error submitting worksheet");
          });
      }
    },
    async sumWeight() {
      this.sum = await sumWorksheetWeight(this.localWorksheet);
    },
    async loadProductInfo() {
      if (this.localWorksheet.productId) {
        return await this.fetchCategory(this.localWorksheet.productId).then(
          (resp) => {
            this.productInfo = resp;

            return this.productInfo;
          }
        );
      } else {
        return null;
      }
    },
    async deleteWorksheet() {
      let confirm = await this.$refs.confirm.open(
        this.$vuetify.lang.t("$vuetify.base.confirmDelete"),
        this.$vuetify.lang.t("$vuetify.worksheet.deleteConfirmMsg")
      );
      if (confirm) {
        this.deleteProcessWorksheet(this.localWorksheet.id)
          .then(() => {
            this.addSuccessMessage(
              this.$vuetify.lang.t("$vuetify.worksheet.deleteSuccessMsg")
            );
            this.$emit("deleted", this.localWorksheet);
          })
          .catch(() => {
            this.addErrorMessage("Server responded with an error.");
          });
      }
    },
    editEntry(entry) {
      if (entry && entry.id) {
        this.currentEntry = entry;
        this.entriesDialog = false;
      }
    },
    async toggleStatus() {
      const originalStatus = this.localWorksheet.status;

      let toggleStatus = "PENDING";

      switch (originalStatus) {
        case "SUBMITTED":
          toggleStatus = "PENDING";
          break;
        case "PENDING":
        case "USED":
          toggleStatus = "SUBMITTED";
          break;
      }

      let confirm = await this.$refs.confirm.open(
        "Confirm Change Status",
        "Please confirm you would like to change status to " + toggleStatus
      );

      if (confirm) {
        if (originalStatus === "USED") {
          this.$axios
            .put(`/processWorksheet/unmark-used/${this.localWorksheet.id}`)
            .then(() => {
              this.localWorksheet.status = toggleStatus;
              this.addSuccessMessage("Worksheet status updated successfully");
              this.$emit("update:worksheet", this.localWorksheet);
            })
            .catch((error) => {
              console.log(error);
              this.localWorksheet.status = originalStatus;
              this.addErrorMessage("Error updating worksheet status");
            });
        } else {
          this.localWorksheet.status = toggleStatus;
          this.updateProcessWorksheet(this.localWorksheet)
            .then(() => {
              this.addSuccessMessage("Worksheet status updated successfully");
              this.$emit("update:worksheet", this.localWorksheet);
            })
            .catch((error) => {
              console.log(error);
              this.localWorksheet.status = originalStatus;
              this.addErrorMessage("Error updating worksheet status");
            });
        }

        if (toggleStatus === "PENDING") {
          this.stage = 1;
        }
      }
    },
    jobChanged() {
      this.$emit("jobUpdated");
    },
    handleWorksheetUpdate(updatedWorksheet) {
      // Reload product info if product id has changed
      if (this.localWorksheet.productId !== updatedWorksheet.productId) {
        this.productInfo = null;
        this.loadProductInfo();
      }
      this.localWorksheet = updatedWorksheet;

      this.$emit("update:worksheet", this.localWorksheet);
      this.$emit("updated", updatedWorksheet);
    },
    handleAddEntry(newEntry) {
      if (Array.isArray(this.localWorksheet.entries)) {
        this.localWorksheet.entries.push(newEntry);
      } else {
        // Initialize entries as an array if not already
        this.localWorksheet.entries = [newEntry];
      }

      this.sumWeight();
      this.addSuccessMessage(
        this.$vuetify.lang.t("$vuetify.worksheet.entryAdded")
      );

      // Emit the updated worksheet
      this.$emit("update:worksheet", this.localWorksheet);
    },

    handleUpdateEntry(updatedEntry) {
      const index = this.localWorksheet.entries.findIndex(
        (e) => e.id === updatedEntry.id
      );
      if (index !== -1) {
        this.$set(this.localWorksheet.entries, index, updatedEntry);
      }
      this.sumWeight();
      this.addSuccessMessage(
        this.$vuetify.lang.t("$vuetify.worksheet.entryUpdated")
      );

      // Emit the updated worksheet
      this.$emit("update:worksheet", this.localWorksheet);
    },

    handleUpdateCurrentEntry(newCurrentEntry) {
      this.currentEntry = newCurrentEntry;
    },
    handleDeleteEntry(deletedEntry) {
      if (deletedEntry && deletedEntry.id) {
        const index = this.localWorksheet.entries.findIndex(
          (e) => e.id === deletedEntry.id
        );
        if (index !== -1) {
          this.localWorksheet.entries.splice(index, 1);
          this.localWorksheet.entryCount =
            typeof this.localWorksheet.entryCount === "number"
              ? this.localWorksheet.entryCount - 1
              : 0;
          this.sumWeight();

          this.addSuccessMessage(
            this.$vuetify.lang.t("$vuetify.worksheet.entryDeleted")
          );

          // Emit the updated worksheet
          this.$emit("update:worksheet", this.localWorksheet);
        }
      }
    },
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>

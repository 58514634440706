import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"text-right"},[_c(VBtn,{attrs:{"to":{ name: 'dbRevisions' }}},[_vm._v("Data Revisions")])],1),_c('div',{staticClass:"text-h6"},[_vm._v(" This audit tool will check data integrity of the system. ")]),_c(VDivider),_c(VSelect,{attrs:{"label":"Check inventories since this closing date","items":_vm.accountClosingList,"item-value":"id","item-text":"label","return-object":"","hide-details":""},model:{value:(_vm.sinceSelectedClosing),callback:function ($$v) {_vm.sinceSelectedClosing=$$v},expression:"sinceSelectedClosing"}}),(_vm.sinceSelectedClosing)?_c('div',{staticClass:"text-caption mt-2"},[_vm._v(" Notes: "+_vm._s(_vm.sinceSelectedClosing.notes)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c(VBtn,{staticClass:"my-8 mx-auto",attrs:{"color":"primary"},on:{"click":_vm.startAudit}},[_vm._v("Start Audit")])],1),_c(VDivider),_c(VProgressLinear,{attrs:{"color":"primary","indeterminate":"","active":_vm.loading}}),_c(VSheet,{staticClass:"pa-5 mb-5",attrs:{"elevation":2}},_vm._l((_vm.auditList),function(audit,index){return _c('div',{key:index,staticClass:"d-flex ma-2 justify-space-between"},[_c('div',[_vm._v(_vm._s(index + 1)+". "+_vm._s(audit.description))]),_c('div',[(audit.status == 'checking')?_c('span',[_c(VProgressCircular,{attrs:{"indeterminate":""}})],1):_c('span',[_vm._v(_vm._s(audit.status))])])])}),0),(!_vm.loading && _vm.result)?_c('div',{staticClass:"font-weight-bold text-center ma-4"},[_vm._v(" "+_vm._s(_vm.result)+" ")]):_vm._e(),_c('div',{ref:"console",staticClass:"text-caption pa-5 console"},[_vm._v(" "+_vm._s(_vm.console)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }